import { Component  } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'detection-limit-error',
  templateUrl: './detection-limit-error.dialog.component.html',
  styleUrls: ['./detection-limit-error.dialog.component.scss']
})
export class DetectionLimitErrorDialogComponent {
  
  constructor(
    private dialogRef: MatDialogRef<DetectionLimitErrorDialogComponent>,
    private router: Router
  ) {
    
  }

  forward() {
    this.dialogRef.close(true);
  }

  retry() {
    this.dialogRef.close(false)
  }
}
