import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { HeaderService } from '@app/services/header.service';
import { BrandingDto } from '../../../../../froexishared/src/dto/BrandingDto';
import SessionStorage from '@app/helpers/SessionStorage';
import { RouterOutletService } from '@app/services/router-outlet.service';
import { CheckInStatusComponent } from '@app/pages/check-in-status/check-in-status.component';
import { Router } from '@angular/router';
@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  navigationVisible: boolean;
  checkinStatusActive : boolean;
  branding: BrandingDto;
  constructor(
    private location: Location,
    private headerService: HeaderService,
    private routerOutletService: RouterOutletService,
    private router : Router
  ) { 
    this.branding = SessionStorage.BrandingData;

  }

  ngOnInit() {
    this.headerService.navigationVisible.subscribe( result=> {
      this.navigationVisible = result;
    })
    this.routerOutletService.routerContentChanged.subscribe( event=> {
      if(event instanceof CheckInStatusComponent ) {
        this.checkinStatusActive = true;
      } else {
        this.checkinStatusActive = false;
      }
    })
  }

  navigateBack() {
    if(!this.checkinStatusActive) {
      this.location.back();
    } else {
      this.router.navigateByUrl('/welcome')
    }
  }

  backStyle() {
    let  styles={};
    styles["background-color"] = SessionStorage.BrandingData.PrimaryColor;
    return styles 
  }
}
