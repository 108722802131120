// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigate-back {
  color: white;
  font-size: 40px;
  margin-top: 0.8em;
}

.header-back {
  background-image: linear-gradient(black, #333333);
}

.logo {
  max-height: 80px;
  max-width: 350px;
  margin: auto;
}

.logo-position {
  line-height: 100px;
  height: 100px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImhlYWRlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSxlQUFBO0VBQ0EsaUJBQUE7QUFDRjs7QUFFQTtFQUNFLGlEQUFBO0FBQ0Y7O0FBRUE7RUFDRSxnQkFBQTtFQUNBLGdCQUFBO0VBQ0EsWUFBQTtBQUNGOztBQUVBO0VBQ0Usa0JBQUE7RUFDQSxhQUFBO0FBQ0YiLCJmaWxlIjoiaGVhZGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm5hdmlnYXRlLWJhY2sge1xuICBjb2xvcjogd2hpdGU7XG4gIGZvbnQtc2l6ZTogNDBweDtcbiAgbWFyZ2luLXRvcDogMC44ZW07XG59XG5cbi5oZWFkZXItYmFjayB7XG4gIGJhY2tncm91bmQtaW1hZ2U6IGxpbmVhci1ncmFkaWVudChibGFjaywgIzMzMzMzMyk7XG59XG5cbi5sb2dvIHtcbiAgbWF4LWhlaWdodDogODBweDtcbiAgbWF4LXdpZHRoOiAzNTBweDtcbiAgbWFyZ2luOiBhdXRvO1xufVxuXG4ubG9nby1wb3NpdGlvbiB7XG4gIGxpbmUtaGVpZ2h0OiAxMDBweDtcbiAgaGVpZ2h0OiAxMDBweDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,iDAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,aAAA;AACF;AACA,wxBAAwxB","sourcesContent":[".navigate-back {\n  color: white;\n  font-size: 40px;\n  margin-top: 0.8em;\n}\n\n.header-back {\n  background-image: linear-gradient(black, #333333);\n}\n\n.logo {\n  max-height: 80px;\n  max-width: 350px;\n  margin: auto;\n}\n\n.logo-position {\n  line-height: 100px;\n  height: 100px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
