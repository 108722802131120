


// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// 3rdparty
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

// Hostware
import SessionStorage from '@app/helpers/SessionStorage';
import { BottomBarService } from '@app/services/bottom-bar.service';
import { HeaderService } from '@app/services/header.service';
import { Language } from '../../../../../froexishared/src/dto/Language'

import { LanguageService } from '../../../../../froexishared/src/services/language.service';
import AvailableLanguages, { LngCodes } from "../../../../../froexishared/src/AvailableLanguages"
import LicenceOptions from '@app/helpers/Options';
import { HttpClient } from '@angular/common/http';
import { HwComputerVisionService } from '@app/services/hw-computer-vision.service';
import { BrandingDto } from '../../../../../froexishared/src/dto/BrandingDto';
@Component({
  selector: 'language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss']
})
export class LanguageSelectionComponent implements OnInit, OnDestroy {

  hasExiLicence = LicenceOptions.HasExiLicence
  hasExoLicence = LicenceOptions.HasExoLicence
  loadLanguages$: Subscription;
  branding : BrandingDto;
  languages: LngCodes[] = [
    // {lngCode:"hu",lngName: "Magyar"},
    // {lngCode:"en",lngName: "English"},
    // {lngCode:"de",lngName: "Deutsch"},
    // {lngCode:"fr",lngName: "Français"},
    // {lngCode:"ru",lngName: "Русский"},
    // {lngCode:"ro",lngName: "Română"},
    // {lngCode:"sk",lngName: "Slovenská"},

  ];
  constructor(
    private translate: TranslateService,
    private router: Router,
    private bottomBarService: BottomBarService,
    private headerService: HeaderService,
    private languageSeervice: LanguageService,
    private hw: HwComputerVisionService
  ) {
    this.branding = SessionStorage.BrandingData;

   }

  ngOnInit() {
    const browserLanguage = this.translate.getBrowserLang();
    this.bottomBarService.setBottomBarVisibility(true);

    if (this.languages.find(item => item.lngCode == browserLanguage)) {
      this.translate.use(browserLanguage)
    } else {
      this.translate.use("en")
    }
    this.headerService.setNavigationVisibility(false);
    this.loadLanguages();
    // this.hw.hello().subscribe(result => {
    //   //console.log('pyhton work!')
    // })
  }

  ngOnDestroy(): void {

  }

  setLanguage(lngCode: string) {
    SessionStorage.ActiveLanguage = lngCode;
    this.translate.setDefaultLang(lngCode)
    this.translate.use(lngCode).subscribe(() => {
      this.router.navigateByUrl('/welcome');
    })

    // navigate to ...
  }

  loadLanguages() {
    this.loadLanguages$ = this.languageSeervice.loadAll(SessionStorage.ReservationInfo.Subscriber, parseInt(SessionStorage.ReservationInfo.Hotelid)).subscribe(result => {
      const lngArr = result as string[];
      if (lngArr) {
        lngArr.forEach(item => {
          this.languages.push(AvailableLanguages.Languages.find(lng => lng.lngCode == item))
        })
      } else {
        // alert("No language files installed")
      }
    })
  }

}
