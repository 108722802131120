import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BottomBarService } from '@app/services/bottom-bar.service';

@Component({
  selector: 'app-photo-guide',
  templateUrl: './photo-guide.component.html',
  styleUrls: ['./photo-guide.component.scss']
})
export class PhotoGuideComponent implements OnInit {

  queryParams: Params;
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private bottomBarService: BottomBarService
  ) { }

  ngOnInit() {
    this.bottomBarService.setBottomBarVisibility(true)
    this.activatedRoute.queryParams.subscribe(params=> {
      this.queryParams=params;
    })
  }
  forwardClick() {
    this.router.navigateByUrl('/photo')
  }
}
