
// Angular
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

// 3rdparty
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators'
// Hostware

@Component({
  selector: 'app-signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.scss']
})
export class SignatureDialogComponent implements OnInit, AfterViewInit {
  
  @ViewChild('canvas',{ static: false }) canvas: ElementRef
  @ViewChild('scaleCanvas',{ static: false }) scaleCanvas: ElementRef

  

  signPad!: ElementRef<HTMLCanvasElement>;
   private signatureImg?: string;
  private sigPadElement: any;
  private context: CanvasRenderingContext2D;
  private isDrawing!: boolean;

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e: any): void {
    this.isDrawing = false;
  }

  onMouseDown(e: any): void {
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e: Event): void {
    e.stopImmediatePropagation()
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }


  constructor(private dialogRef: MatDialogRef<SignatureDialogComponent>) {

 

  }
 

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
  }
  ngAfterViewInit(): void {
    const canvasElement: HTMLCanvasElement = this.canvas.nativeElement;
    this.context = canvasElement.getContext("2d");
    this.context.lineWidth = 3;
    this.context.lineCap = 'round';
    this.context.strokeStyle = '#000'; 
   
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  
  cancel() {
    this.dialogRef.close(undefined)
  }

  submit() {
    const canvasElement: HTMLCanvasElement = this.canvas.nativeElement;
    const scaleCanvas: HTMLCanvasElement  = this.scaleCanvas.nativeElement;
    scaleCanvas.width = 400;
    scaleCanvas.height = 200;
    let scaleContext = scaleCanvas.getContext("2d")
    this.context = canvasElement.getContext("2d");
    scaleContext.drawImage(canvasElement,0,0)
    this.signatureImg = canvasElement.toDataURL('image/png');
    const scaledImage = scaleCanvas.toDataURL('image/png')
    this.dialogRef.close(scaledImage)
  }

  clearCanvas() {
    const canvasElement: HTMLCanvasElement = this.canvas.nativeElement;
    this.context.clearRect(
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );
    this.context.beginPath();
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  private relativeCoords(event: any): { x: number; y: number } {
    const bounds = event.target.getBoundingClientRect();
    const cords = {
      clientX: event.clientX || event.changedTouches[0].clientX,
      clientY: event.clientY || event.changedTouches[0].clientY,
    };
    const x = cords.clientX - bounds.left;
    const y = cords.clientY - bounds.top;
    return { x, y };
  }
  //#endregion =================================================================================================================


}
