// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-size {
  font-size: 40px;
}

.icon-pack-style {
  background-color: #ececec;
}

.photo-label {
  position: absolute;
  top: 50%;
  margin: auto;
  padding: 10px;
  text-align: center;
  width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBob3RvLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZUFBQTtBQUNGOztBQUVBO0VBQ0UseUJBQUE7QUFDRjs7QUFFQTtFQUNFLGtCQUFBO0VBQ0EsUUFBQTtFQUNBLFlBQUE7RUFDQSxhQUFBO0VBQ0Esa0JBQUE7RUFDQSxXQUFBO0FBQ0YiLCJmaWxlIjoicGhvdG8uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuaWNvbi1zaXplIHtcbiAgZm9udC1zaXplOiA0MHB4O1xufVxuXG4uaWNvbi1wYWNrLXN0eWxlIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2VjZWNlYztcbn1cblxuLnBob3RvLWxhYmVsIHtcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICB0b3A6IDUwJTtcbiAgbWFyZ2luOiBhdXRvO1xuICBwYWRkaW5nOiAxMHB4O1xuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gIHdpZHRoOiAxMDAlO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/pages/photo/photo.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;AACF;AACA,4pBAA4pB","sourcesContent":[".icon-size {\n  font-size: 40px;\n}\n\n.icon-pack-style {\n  background-color: #ececec;\n}\n\n.photo-label {\n  position: absolute;\n  top: 50%;\n  margin: auto;\n  padding: 10px;\n  text-align: center;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
