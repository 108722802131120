import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-simple-payment-cancel',
  templateUrl: './simple-payment-cancel.component.html',
  styleUrls: ['./simple-payment-cancel.component.scss']
})
export class SimplePaymentCancelComponent {
  /**
   *
   */
  constructor(private router: Router) {
  }

  backClick() {
    // clear sessionstore data
    // navigate back
    this.router.navigateByUrl('/checkinstatus')
  }
}
