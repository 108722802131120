// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  width: 100vw;
  padding-top: 3rem;
}

.wrapper {
  text-align: center;
}

.button-ok {
  padding-top: 3rem;
}

.checked-in {
  color: #41c300;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNpbXBsZS1wYXltZW50LXN1Y2Nlc3MuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxZQUFBO0VBQ0EsaUJBQUE7QUFDSjs7QUFFQTtFQUNJLGtCQUFBO0FBQ0o7O0FBT0E7RUFDSSxpQkFBQTtBQUpKOztBQU9BO0VBQ0ksY0FBQTtBQUpKIiwiZmlsZSI6InNpbXBsZS1wYXltZW50LXN1Y2Nlc3MuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuaGVhZGVyLWNvbnRhaW5lciB7XG4gICAgd2lkdGg6IDEwMHZ3O1xuICAgIHBhZGRpbmctdG9wOiAzcmVtO1xufVxuXG4ud3JhcHBlciB7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xufVxuXG4vLyBtYXQtaWNvbiB7XG4vLyAgICAgdHJhbnNmb3JtOiBzY2FsZSgyKTtcbi8vICAgICBjb2xvcjogZ3JlZW47XG4vLyB9XG5cbi5idXR0b24tb2sge1xuICAgIHBhZGRpbmctdG9wOiAzcmVtO1xufVxuXG4uY2hlY2tlZC1pbiB7XG4gICAgY29sb3I6ICM0MWMzMDA7XG59Il19 */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/pages/simple-payment-success/simple-payment-success.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAOA;EACI,iBAAA;AAJJ;;AAOA;EACI,cAAA;AAJJ;AACA,wuBAAwuB","sourcesContent":[".header-container {\n    width: 100vw;\n    padding-top: 3rem;\n}\n\n.wrapper {\n    text-align: center;\n}\n\n// mat-icon {\n//     transform: scale(2);\n//     color: green;\n// }\n\n.button-ok {\n    padding-top: 3rem;\n}\n\n.checked-in {\n    color: #41c300;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
