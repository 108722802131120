import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import SessionStorage from '@app/helpers/SessionStorage';
import { BigFish } from '@app/interfaces/BigFish';
import { BigFishPayMode } from '@app/interfaces/BigFishPayMode';
import { ExcludedServicesInformationDialogComponent } from '../../dialogs/excluded-services-information-dialog/excluded-services-information-dialog.component';
import { Router } from '@angular/router';
import { SelectionChange } from '@angular/cdk/collections';
import { MatSelectionListChange } from '@angular/material/list';

@Component({
  selector: 'bigfish-payment-options',
  templateUrl: './bigfish-payment-options.component.html',
  styleUrls: ['./bigfish-payment-options.component.scss']
})



export class BigfishPaymentOptionsComponent implements OnInit {

  @Input() payModes: BigFishPayMode[] = []


  creditCardModes: BigFishPayMode[] = [];
  szepCardModes: BigFishPayMode[] = []


  editorForm: FormGroup

  constructor(
    private szepCardAlertDialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,

  ) {

    this.editorForm = fb.group({
      creditCardOptions: new FormControl(''),
      szepCardOptions: new FormControl('')
    })
  }
  ngOnInit(): void {
    this.payModes.forEach(payMode => {
      if (payMode.Type == "CREDITCARD") {
        this.creditCardModes.push(payMode)
      } else {
        this.szepCardModes.push(payMode)
      }
    })
    this.editorForm.controls["creditCardOptions"].patchValue(this.creditCardModes[0].ShortName)
    SessionStorage.SelectedPaymentMode = "BIGFISH-" + this.creditCardModes[0].ShortName
    SessionStorage.SelectedPaymentType = "CREDITCARD"
    if (SessionStorage.HasOnlyExludedServices == 'y')  {
      this.editorForm.controls["szepCardOptions"].disable()
    }
  }


  creditCardOptionsChanged(event) {
    this.editorForm.controls["szepCardOptions"].patchValue("")
    SessionStorage.SelectedPaymentMode = 'BIGFISH-' + event.value; 
    SessionStorage.SelectedPaymentType = "CREDITCARD"

  }
  szepCardOptionsChanged(event) {
    this.editorForm.controls["creditCardOptions"].patchValue("")
    SessionStorage.SelectedPaymentMode = "BIGFISH-" + event.value; 
    SessionStorage.SelectedPaymentType = "SZEPCARD"
  }

}

export class BigFishWrapper {
  BigFishData: BigFishPayMode[]
  CanBePayedWithSzepCard: boolean
}


// ha HasOnlyExludedServices akkor a  szepcard típusú elemeket le kell tiltani
