import { APP_INITIALIZER, InjectionToken, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { persistState } from '@datorama/akita';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import localizedFormat from 'dayjs/plugin/localizedFormat';


import 'dayjs/locale/cs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/hr';
import 'dayjs/locale/hu';
import 'dayjs/locale/pl';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/sk';
import 'dayjs/locale/uk';

import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeHu from '@angular/common/locales/hu';
import localeRo from '@angular/common/locales/ro';
import localeRu from '@angular/common/locales/ru';


import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '@environments/environment';

import { ActivatedRoute } from '@angular/router';
import { ChatModule } from '@globalshared/chat/src/lib/chat.module';
import { ChatService } from '@globalshared/chat/src/lib/chat.service';
import { provideNgxMask } from 'ngx-mask';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { take } from 'rxjs';
import { FroexisharedModule } from '../../../froexishared/src/lib/froexishared.module';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageCaptureComponent } from './components/image-capture/image-capture.component';
import { ImageCapture2Component } from './components/image-capture2/image-capture2.component';
import { SimpleConditionsApprovalComponent } from './components/simple-conditions-approval/simple-conditions-approval.component';
import { BigfishPaymentDialogComponent } from './dialogs/bigfish-payment-dialog/bigfish-payment-dialog.component';
import { ChatDialogComponent } from './dialogs/chat-dialog/chat-dialog.component';
import { DetectionLimitErrorDialogComponent } from './dialogs/detection-limit-error-dialog/detection-limit-error.dialog.component';
import { GenericMessageDialogComponent } from './dialogs/generic-message-dialog/generic-message-dialog.component';
import { SendInvitationDialogComponent } from './dialogs/send-invitation-dialog/send-invitation-dialog.component';
import { SignatureDialogComponent } from './dialogs/signature-dialog/signature-dialog.component';
import { SimpleVerificationDialogComponent } from './dialogs/simple-verification-dialog/simple-verification-dialog.component';
import { YesNoDialogComponent } from './dialogs/yes-no-dialog/yes-no-dialog.component';
import { FontFamilyDirective } from './directives/font-family.directive';
import { UrlData } from './interfaces/UrlData';
import { AdditionalDataComponent } from './pages/additional-data/additional-data.component';
import { AgeSelectionComponent } from './pages/age-selection/age-selection.component';
import { BalanceComponent } from './pages/balance/balance.component';
import { BigfishPaymentHandlerComponent } from './pages/bigfish-payment-handler/bigfish-payment-handler.component';
import { BillViewComponent } from './pages/bill-view/bill-view.component';
import { ByeByeComponent } from './pages/bye-bye/bye-bye.component';
import { CheckInStatusComponent } from './pages/check-in-status/check-in-status.component';
import { FakeExternalLinkComponent } from './pages/fake-external-link/fake-external-link.component';
import { IdVerificationOkComponent } from './pages/id-verification-ok/id-verification-ok.component';
import { InvoiceDetailsComponent } from './pages/invoice-details/invoice-details.component';
import { LanguageSelectionComponent } from './pages/language-selection/language-selection.component';
import { PhotoGuideComponent } from './pages/photo-guide/photo-guide.component';
import { PhotoComponent } from './pages/photo/photo.component';
import { RoomsAndEmailsComponent } from './pages/rooms-and-emails/rooms-and-emails.component';
import { ShowCheckInStepsComponent } from './pages/show-check-in-steps/show-check-in-steps.component';
import { ShowFillFormMessageComponent } from './pages/show-fill-form-message/show-fill-form-message.component';
import { SimplePaymentCancelComponent } from './pages/simple-payment-cancel/simple-payment-cancel.component';
import { SimplePaymentErrorComponent } from './pages/simple-payment-error/simple-payment-error.component';
import { SimplePaymentSuccessComponent } from './pages/simple-payment-success/simple-payment-success.component';
import { SimplePaymentTimeoutComponent } from './pages/simple-payment-timeout/simple-payment-timeout.component';
import { Under14InfoComponent } from './pages/under14-info/under14-info.component';
import { VizaDataComponent } from './pages/viza-data/viza-data.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { CurrencyFormatPipe } from './pipes/HuCurrencyFormat.pipe';
import { HwAuthInterceptorService } from './services/hw-auth-interceptor.service';
import { UrlDataService } from './services/urlData.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ExcludedServicesInformationDialogComponent } from './dialogs/excluded-services-information-dialog/excluded-services-information-dialog.component';
import { BigfishPaymentOptionsComponent } from './components/bigfish-payment-options/bigfish-payment-options.component';

dayjs.extend(localizedFormat)

dayjs.extend(customParseFormat)
dayjs.extend(isLeapYear)

registerLocaleData(localeHu, 'hu');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeRo, 'ro');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeFr, 'fr');


const storage = persistState();
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PhotoComponent,
    HeaderComponent,
    FooterComponent,
    ImageCaptureComponent,
    ImageCapture2Component,
    LanguageSelectionComponent,
    WelcomeComponent,
    FontFamilyDirective,
    CheckInStatusComponent,
    AgeSelectionComponent,
    ShowCheckInStepsComponent,
    ShowFillFormMessageComponent,
    PhotoGuideComponent,
    RoomsAndEmailsComponent,
    GenericMessageDialogComponent,
    ByeByeComponent,
    IdVerificationOkComponent,
    VizaDataComponent,
    DateSelectorComponent,
    AdditionalDataComponent,
    SignatureDialogComponent,
    Under14InfoComponent,
    BalanceComponent,
    InvoiceDetailsComponent,
    SendInvitationDialogComponent,
    BillViewComponent,
    CurrencyFormatPipe,
    YesNoDialogComponent,
    FakeExternalLinkComponent,
    SimplePaymentErrorComponent,
    SimplePaymentSuccessComponent,
    SimpleConditionsApprovalComponent,
    SimplePaymentTimeoutComponent,
    SimplePaymentCancelComponent,
    DetectionLimitErrorDialogComponent,
    SimpleVerificationDialogComponent,
    BigfishPaymentDialogComponent,
    ChatDialogComponent,
    BigfishPaymentHandlerComponent,
    ExcludedServicesInformationDialogComponent,
    BigfishPaymentOptionsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    ChatModule,
    SocketIoModule.forRoot({ url: environment.chatBackendUrl } as SocketIoConfig),

    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    FroexisharedModule.forRoot(environment),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

  ],
  exports: [
    FontFamilyDirective,
    ChatDialogComponent,
  ],
  providers: [
    { provide: MatDialogRef, useValue: {}},
    ChatService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: HTTP_INTERCEPTORS, useClass: HwAuthInterceptorService, multi: true },
    { provide: 'persistStorage', useValue: storage },
    {
      provide: APP_INITIALIZER,
      useFactory: lngInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    { provide: 'environment', useValue: environment },
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(
    private activatedRoute: ActivatedRoute,
    private urlDataService: UrlDataService
  ) {
    this.activatedRoute.queryParams
      .pipe(
        take(2))
      .subscribe((urlData: UrlData) => {
        if (urlData.guestStayUuid && urlData.hotel3pId) {
          localStorage.removeItem('urlData')
          localStorage.setItem('urlData', JSON.stringify(urlData))
          this.urlDataService.setUrlData(urlData)
        }
      })
  }
}

export function lngInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      let lang = translate.getBrowserLang() === 'en' ? 'en' : 'hu'
      translate.use(lang).subscribe({
        next: () => {
          console.info(`Successfully initialized '${lang}' language.'`);
        },
        error: () => {
          console.error(`Problem with '${lang}' language initialization.'`);
        },
        complete() {
          resolve(null);
        },
      })
    });
  });
}

