import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import SimpleCallBackUrlHelper from '@app/helpers/SimpleCallBackUrlHelper';

@Component({
  selector: 'app-simple-payment-timeout',
  templateUrl: './simple-payment-timeout.component.html',
  styleUrls: ['./simple-payment-timeout.component.scss']
})
export class SimplePaymentTimeoutComponent {

  transactionId: string;
  constructor(private router: Router,
    private route: ActivatedRoute

  ) {
  }

  ngOnInit(): void {
    let paramR = this.route.snapshot.queryParamMap.get('r');
    const result = SimpleCallBackUrlHelper.GetResultR(paramR);
    this.transactionId = result.t;
  }
  backClick() {
    // clear sessionstore data
    // navigate back
    this.router.navigateByUrl('/checkinstatus')
  }

  tryAgain() {
    this.router.navigateByUrl('/billview')
  }

}
