import { EventEmitter, Injectable, Output } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterOutletService {

  constructor() { }

  @Output() routerContentChanged = new EventEmitter<RouterOutlet>()

  setRouterContentChanged(content : RouterOutlet) {
    this.routerContentChanged.emit(content);
  }
}
