import { Renderer2, Directive,ElementRef} from '@angular/core';

import SessionStorage from '@app/helpers/SessionStorage';

@Directive({
  selector: "[fontFamily]"
})
export class FontFamilyDirective {

  constructor(private el: ElementRef, renderer:Renderer2) {
    if( SessionStorage.BrandingData) {
      renderer.setStyle(el.nativeElement,'font-family',SessionStorage.BrandingData.FontName);
    }
   
  }
 
}
