import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GuestLogin } from '@app/dto/GuestLogin';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor( private http:HttpClient) {

   }

   CreateGuestToken (data: GuestLogin) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http.post(environment.exiBackendUrl+"/api/CreateGuestToken",data,{headers})
   }
}
