export default class DateHelper {
  public static FormatDate( shortDate : string ) : string {
    let ret : string
    let prefix : string;
    const y = new Date().getFullYear().toString();
    const thisYear = parseInt(y.substring(2,4))
    const yearFromStartDate = parseInt(shortDate.substring(0,2))

    if( yearFromStartDate > thisYear) {
      prefix = "19"
    } else {
      prefix = "20"
    }
    ret = prefix +  shortDate.substring(0,2) + "-" + shortDate.substring(2,4) + "-" +shortDate.substring(4,6)
    return  ret 
  }
}