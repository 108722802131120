import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ReservationService {

  constructor(private http: HttpClient) { }

  GetReservationInfo(guestStayUuId: string, invite?: string) {
    return this.http.get(environment.exiBackendUrl + "/api/exi/Reservation/GetReservationInfo/" + guestStayUuId + "/" + invite)
  }
}
