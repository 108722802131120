// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  width: 100vw;
  padding-top: 3rem;
}

.wrapper {
  text-align: center;
}

.button-ok {
  padding-top: 3rem;
}

.error-icon {
  color: red;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNpbXBsZS1wYXltZW50LWVycm9yLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0ksWUFBQTtFQUNBLGlCQUFBO0FBQ0o7O0FBRUE7RUFDSSxrQkFBQTtBQUNKOztBQUVBO0VBQ0ksaUJBQUE7QUFDSjs7QUFFQTtFQUNJLFVBQUE7QUFDSiIsImZpbGUiOiJzaW1wbGUtcGF5bWVudC1lcnJvci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5oZWFkZXItY29udGFpbmVyIHtcbiAgICB3aWR0aDogMTAwdnc7XG4gICAgcGFkZGluZy10b3A6IDNyZW07XG59XG5cbi53cmFwcGVyIHtcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG59XG5cbi5idXR0b24tb2sge1xuICAgIHBhZGRpbmctdG9wOiAzcmVtO1xufVxuXG4uZXJyb3ItaWNvbiB7XG4gICAgY29sb3I6IHJlZDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/pages/simple-payment-error/simple-payment-error.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,UAAA;AACJ;AACA,4nBAA4nB","sourcesContent":[".header-container {\n    width: 100vw;\n    padding-top: 3rem;\n}\n\n.wrapper {\n    text-align: center;\n}\n\n.button-ok {\n    padding-top: 3rem;\n}\n\n.error-icon {\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
