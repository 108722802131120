import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BigFishPaymentResponse,BigFishPaymentResultCode } from '@app/dto/BigFishPaymentResponse';
import SessionStorage from '@app/helpers/SessionStorage';
import { environment } from '@environments/environment';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BigfishService {

  constructor(private http: HttpClient) { }


  


  GetBigFishResult(transId: string ) {
     return this.http.get(`${environment.exiBackendUrl}/api/exi/billing/getbigfishresult/${transId}` )
    // const res : BigFishPaymentResponse = {
    //   Amount: "100",
    //   Currency: "HUF",
    //   ProviderTransactionId :"12345",
    //   ResultCode : BigFishPaymentResultCode.PENDING,
    //   ResultMessage  : "Hello Bello",
    //   SzepPocket : "",
    //   TransactionId : "678988"

    // }
    // return of(res)
  
  }



  // GetBigFishResult(transId: string) {
  //   const res : BigFishPaymentResponse = {
  //     Amount: "100",
  //     Currency: "HUF",
  //     ProviderTransactionId :"12345",
  //     ResultCode : BigFishPaymentResultCode.PENDING,
  //     ResultMessage  : "Hello Bello",
  //     SzepPocket : "",
  //     TransactionId : "678988"

  //   }
  //   return of(res)
  // }


  RefreshBigFishResult(transId : string) {
   return this.http.get(`${environment.exiBackendUrl}/api/exi/billing/refreshbigfishresult/${transId}` )
   
    // const res : BigFishPaymentResponse = {
    //   Amount: "100",
    //   Currency: "HUF",
    //   ProviderTransactionId :"12345",
    //   ResultCode : BigFishPaymentResultCode.PENDING,
    //   ResultMessage  : "Hello Bello Success",
    //   SzepPocket : "",
    //   TransactionId : "678988"
    // }
    // return of(res)
  }
}
