import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({providedIn:'root'})
export class SpinnerService {


@Output() spinnerVisibilityChanged = new EventEmitter<boolean>();
constructor() { }

public setSpinnerVisibility( visibility: boolean) {
  this.spinnerVisibilityChanged.emit(visibility);
}

}
