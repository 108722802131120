export interface BigFishPaymentResponse {
  TransactionId : string;
  ResultCode: BigFishPaymentResultCode
  ResultMessage: string
  Amount : string
  Currency : string
  ProviderTransactionId: string
  SzepPocket: string
}

export enum  BigFishPaymentResultCode  {
  SUCCESSFUL = "SUCCESSFUL",
  PENDING = "PENDING",
  OPEN= "OPEN",
  ERROR = "ERROR",
  CANCELED = "CANCELED",
  TIMEOUT = "TIMEOUT"
}

  /*
A tranzakció státusza lehet:

  SUCCESSFUL

  PENDING

  OPEN

  ERROR

  CANCELED

  TIMEOUT

*/