import { Component } from '@angular/core';

@Component({
  selector: 'app-fake-external-link',
  templateUrl: './fake-external-link.component.html',
  styleUrls: ['./fake-external-link.component.scss']
})
export class FakeExternalLinkComponent {

}
