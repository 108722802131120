// Angular
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// 3rdparty

// Hostware

@Component({
  selector: 'app-excluded-services-information-dialog',
  templateUrl: './excluded-services-information-dialog.component.html',
  styleUrls: ['./excluded-services-information-dialog.component.scss']
})
export class ExcludedServicesInformationDialogComponent {

  /**
   *
   */
  constructor(
    private dialogRef: MatDialogRef<ExcludedServicesInformationDialogComponent>,

  ) {
    
  }
  cancel() {
    this.dialogRef.close(undefined)
  }

  submit() {
    this.dialogRef.close('ok')
  }
}
