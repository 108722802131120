// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check-icon {
  font-size: 60px;
  text-align: center;
  color: #ACA100;
  height: 60px;
  width: 60px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImV4Y2x1ZGVkLXNlcnZpY2VzLWluZm9ybWF0aW9uLWRpYWxvZy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLGVBQUE7RUFDQSxrQkFBQTtFQUNBLGNBQUE7RUFDQSxZQUFBO0VBQ0EsV0FBQTtBQUNKIiwiZmlsZSI6ImV4Y2x1ZGVkLXNlcnZpY2VzLWluZm9ybWF0aW9uLWRpYWxvZy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jaGVjay1pY29uIHtcbiAgICBmb250LXNpemU6IDYwcHg7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuICAgIGNvbG9yOiAjQUNBMTAwO1xuICAgIGhlaWdodDogNjBweDtcbiAgICB3aWR0aDogNjBweDtcbiAgfSJdfQ== */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/dialogs/excluded-services-information-dialog/excluded-services-information-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;AACJ;AACA,oiBAAoiB","sourcesContent":[".check-icon {\n    font-size: 60px;\n    text-align: center;\n    color: #ACA100;\n    height: 60px;\n    width: 60px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
