import { Injectable } from '@angular/core';
import { Balance } from '@app/interfaces/Balance';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BalanceService {

  constructor() { }

  loadAll() : Observable<Balance[]> {
    const balanceItems: Balance[] =  [{
      Amount: 2,
      BalanceDate: '2023.05.19',
      ChargeId: "xxx",
      Order3pId: "xxx",
      Quantity: 10,
      Room: '101',
      ServiceId: 1,
      ServiceName: 'Lávaköves masszázs',
      Price: 14500
    },
    {
      Amount: 4,
      BalanceDate: '2023.05.20',
      ChargeId: "xxx",
      Order3pId: "xxx",
      Quantity: 4,
      Room: '101',
      ServiceId: 2,
      ServiceName: 'Minibár fogyasztás',
      Price: 14500
    },
    {
      Amount: 1,
      BalanceDate: '2023.05.20',
      ChargeId: "xxx",
      Order3pId: "xxx",
      Quantity: 1,
      Room: '101',
      ServiceId: 5,
      ServiceName: 'Szauna',
      Price: 14500
    },
    {
      Amount: 2,
      BalanceDate: '2023.05.19',
      ChargeId: "xxx",
      Order3pId: "xxx",
      Quantity: 10,
      Room: '101',
      ServiceId: 1,
      ServiceName: 'Lávaköves masszázs',
      Price: 14500
    },
    {
      Amount: 4,
      BalanceDate: '2023.05.22',
      ChargeId: "xxx",
      Order3pId: "xxx",
      Quantity: 4,
      Room: '101',
      ServiceId: 2,
      ServiceName: 'Minibár fogyasztás',
      Price: 14500
    },
    {
      Amount: 1,
      BalanceDate: '2023.05.22',
      ChargeId: "xxx",
      Order3pId: "xxx",
      Quantity: 3,
      Room: '101',
      ServiceId: 5,
      ServiceName: 'Szauna',
      Price: 14500
    },
    {
      Amount: 2,
      BalanceDate: '2023.05.17',
      ChargeId: "xxx",
      Order3pId: "xxx",
      Quantity: 10,
      Room: '101',
      ServiceId: 1,
      ServiceName: 'Lávaköves masszázs',
      Price: 14500
    },
    {
      Amount: 4,
      BalanceDate: '2023.05.17',
      ChargeId: "xxx",
      Order3pId: "xxx",
      Quantity: 2,
      Room: '101',
      ServiceId: 2,
      ServiceName: 'Minibár fogyasztás',
      Price: 14500
    },
    {
      Amount: 1,
      BalanceDate: '2023.05.17',
      ChargeId: "xxx",
      Order3pId: "xxx",
      Quantity: 2,
      Room: '101',
      ServiceId: 5,
      ServiceName: 'Szauna',
      Price: 14500
    },
    {
      Amount: 1,
      BalanceDate: '2023.05.17',
      ChargeId: "xxx",
      Order3pId: "xxx",
      Quantity: 2,
      Room: '101',
      ServiceId: 5,
      ServiceName: 'Előleg',
      Price: -14500
    }
    ]
    return of(balanceItems)
  }
}
