// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.check-icon {
  font-size: 60px;
  text-align: center;
  color: #ACA100;
  height: 60px;
  width: 60px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRldGVjdGlvbi1saW1pdC1lcnJvci5kaWFsb2cuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxlQUFBO0VBQ0Esa0JBQUE7RUFDQSxjQUFBO0VBQ0EsWUFBQTtFQUNBLFdBQUE7QUFDSiIsImZpbGUiOiJkZXRlY3Rpb24tbGltaXQtZXJyb3IuZGlhbG9nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNoZWNrLWljb24ge1xuICAgIGZvbnQtc2l6ZTogNjBweDtcbiAgICB0ZXh0LWFsaWduOiBjZW50ZXI7XG4gICAgY29sb3I6ICNBQ0ExMDA7XG4gICAgaGVpZ2h0OiA2MHB4O1xuICAgIHdpZHRoOiA2MHB4O1xuICB9Il19 */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/dialogs/detection-limit-error-dialog/detection-limit-error.dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;AACJ;AACA,4gBAA4gB","sourcesContent":[".check-icon {\n    font-size: 60px;\n    text-align: center;\n    color: #ACA100;\n    height: 60px;\n    width: 60px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
