import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LoadCsvService {

  private http = inject(HttpClient)
  private translate = inject(TranslateService)

  getDataFromCsv(path: string, separateOperator: string, array, zipOrLang: string) {
    let defLanguage = this.translate.getDefaultLang();
    this.http.get(path, { responseType: 'text' }).subscribe({
      next: (data) => {
        data.split(/\r?\n/).forEach(country => {
          let splitedData = country.split(separateOperator)
          if (zipOrLang === 'lang') {
            array.push(
              {
                Name: splitedData[0],
                Id: splitedData[1],
                IsEu: splitedData[2],
              }
            )
          }
          if (zipOrLang === 'zipCode') {
            array.push(
              {
                zipCode: splitedData[0],
                City: splitedData[1],
                Country: splitedData[2]
              })
          }
        })
        return array
      },
      error: (err) => {
        console.error(err)
      }
    });
  }


}
