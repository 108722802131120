import { HwToken } from "@app/interfaces/Token";
import SessionStorage from "./SessionStorage"
import jwt_decode from 'jwt-decode';

export default class LicenceOptions {
  public static get  HasExiLicence() : boolean {

    const token  = SessionStorage.GuestData.Token
    const decodedToken = jwt_decode(token) as HwToken
    return decodedToken.ExiOptions.includes("EXI") ? true : false
    
  }

  public static get HasExoLicence() : boolean {
    const token  = SessionStorage.GuestData.Token
    const decodedToken = jwt_decode(token) as HwToken
    return decodedToken.ExiOptions.includes("EXO") ? true : false
  }
}