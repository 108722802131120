export default class SimpleCallBackUrlHelper {
  public static GetResultR( base64Data: string) : SimpleCallbackResultR | undefined {
    const decodedStr =  atob(base64Data);
    const resultR = JSON.parse(decodedStr) as SimpleCallbackResultR;
    return resultR ? resultR : undefined
  }
}
export interface SimpleCallbackResultR {
  r: number
  t: string
  e: string
  m: string
  o: string
}

export interface SimpleCallbackResultS {

}
