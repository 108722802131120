import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoomGuest } from '@app/dto/ReservationInfo';
import SessionStorage from '@app/helpers/SessionStorage';
import { GuestStayStatus } from '@app/types/Types';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class InvitationEmailService {

  constructor(private http: HttpClient) { }


  public sendInvitation(data: RoomGuest) {
    const lng = SessionStorage.ActiveLanguage;
    const headers = new HttpHeaders().set("Content-Type","application/json");
    data.Status = GuestStayStatus.EMPTY;
    data.Arrival = null;
    data.Departure = null;
    data.VizaData = null;
    data.FaceCheck = null;
    return this.http.post(environment.exiBackendUrl+"/api/exi/reservation/SendInvitations/" + lng,data,{headers})
  
  }
  
}