
// Angular
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, combineLatest, skip, takeUntil } from 'rxjs';
// Hostware
import { BalanceType } from '@app/types/Types';
import { Balance } from '@app/interfaces/Balance';
import { BalanceService } from '@app/services/balance.service';
import { SubscriberParameterService } from '../../../../../froexishared/src/services/SubscriberParameter.service';
import SessionStorage from '@app/helpers/SessionStorage';
import { SubscriberParameter } from '../../../../../froexishared/src/dto/SubscriberParameter';
import { CurrencySettings } from '../../../../../froexishared/src/dto/CurrencySettingsDto';
import { BillingService } from '@app/services/billing.service';
import { RoomGuest } from '@app/dto/ReservationInfo';
import { StartBillingResponse } from '@app/interfaces/StartBillingResponse';
import { SpinnerService } from '@app/services/spinner.service';

import { GenericMessageDialogComponent } from '../../dialogs/generic-message-dialog/generic-message-dialog.component';
import { GenericMessage } from '@app/interfaces/generic-message';
import dayjs from 'dayjs';
import { HeaderService } from '@app/services/header.service';
import { YesNoDialogComponent } from '@app/dialogs/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss']
})

@AutoUnsubscribe()
export class BalanceComponent implements OnInit, OnDestroy {

  loadBalance$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();

  startBillingRespose: StartBillingResponse;
  dataSource: MatTableDataSource<Balance>;
 
  
  queryParams: Params;
  showDeposit: boolean = false;
  loaded: boolean = false;
  currencySettings: CurrencySettings;
  total: number = 0
  paymentDisabled: boolean;
  sumDeposit: number = 0;

  constructor(
    private balanceService: BalanceService,
    private router: Router,
    private translate: TranslateService,
    private subscriberParamaterService: SubscriberParameterService,
    private activatedRoute: ActivatedRoute,
    private billingService: BillingService,
    private spinnerService: SpinnerService,
    private errorDialog: MatDialog,
    private dialog: MatDialog,
  ) { }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {

      this.showDeposit = true //this.queryParams["showdeposit"] == "true" ? true : false;

      const selectedGuest = JSON.parse(sessionStorage.getItem('sgd')) as RoomGuest

      this.queryParams = params;
      
      this.spinnerService.setSpinnerVisibility(true);
      const combinedSources = combineLatest({
        startbillingResponse: this.billingService.StartBilling(selectedGuest.GuestStayUuId, SessionStorage.ActiveLanguage, true),
        // currencySettings: this.subscriberParamaterService.loadByTypeCode(SessionStorage.ReservationInfo.Subscriber,
        //   parseInt(SessionStorage.ReservationInfo.Hotelid), "CURRENCY_SETTINGS"),
        subscriberParameters: this.subscriberParamaterService.loadAll(SessionStorage.ReservationInfo.Subscriber,
          parseInt(SessionStorage.ReservationInfo.Hotelid))
      }).pipe(takeUntil(this.destroy$)).subscribe({
        next: (values) => {
          this.startBillingRespose = values.startbillingResponse as StartBillingResponse;
          this.currencySettings = JSON.parse((values.subscriberParameters as SubscriberParameter[]).find(item => item.Param_Typecode == "CURRENCY_SETTINGS").Param_Json_Value) as CurrencySettings // values.currencySettings as SubscriberParameter;
          const excludeds = (values.subscriberParameters as SubscriberParameter[]).find(item => item.Param_Typecode == "EXCLUDED_SERVICES")
            .Param_String_Value.split(",")
          let hasExcludedServices: boolean = false;
          let hasOnlyExcludedServices: boolean = true;
          SessionStorage.HasExludedService = 'n';
          SessionStorage.HasOnlyExludedServices = 'n'
          excludeds.forEach(excluded => {
            this.startBillingRespose.Transaction.RoomStayCharges.forEach(charges => {
              charges.GuestStayCharges.forEach(gguestCharge => {
                gguestCharge.GuestCharges.forEach( ggguestCharge => {
                  if (ggguestCharge.ServiceId == excluded && !hasExcludedServices) {
                    hasExcludedServices = true;
                  }
                  if (ggguestCharge.ServiceId != excluded && hasOnlyExcludedServices) {
                    hasOnlyExcludedServices = false;
                  }
                });
              })
              charges.RoomStayCharges.forEach(roomstayCharge=> {
                if(roomstayCharge.ServiceId == excluded && !hasExcludedServices) {
                  hasExcludedServices = true
                }
                if (roomstayCharge.ServiceId != excluded && hasOnlyExcludedServices) {
                  hasOnlyExcludedServices = false;
                }
              })
            })

          })
          SessionStorage.HasExludedService = hasExcludedServices ? 'y' : 'n'
          SessionStorage.HasOnlyExludedServices = hasOnlyExcludedServices ? 'y' : 'n'

          // calculate total
          let percent0Total = this.startBillingRespose.PayValues["0"]?.ToPay ? this.startBillingRespose.PayValues["0"].ToPay : 0
          let percent5Total = this.startBillingRespose.PayValues["5"]?.ToPay ? this.startBillingRespose.PayValues["5"].ToPay : 0
          let percent27Total = this.startBillingRespose.PayValues["27"]?.ToPay ? this.startBillingRespose.PayValues["27"].ToPay : 0

          let percent0Deposit = this.startBillingRespose.PayValues["0"]?.Deposit ? this.startBillingRespose.PayValues["0"].Deposit : 0
          let percent5Deposit = this.startBillingRespose.PayValues["5"]?.Deposit ? this.startBillingRespose.PayValues["5"].Deposit : 0
          let percent27Deposit = this.startBillingRespose.PayValues["27"]?.Deposit ? this.startBillingRespose.PayValues["27"].Deposit : 0

          this.total = percent0Total + percent5Total + percent27Total - (percent0Deposit + percent5Deposit + percent27Deposit)
          this.sumDeposit = percent0Deposit + percent5Deposit + percent27Deposit
          // this.startBillingRespose.PayValues.forEach(charge => {
          //   charge.RoomStayCharges.forEach(item => {
          //     this.total += (item.Amount * item.Quantity)
          //   })
          // })
          if (this.total <= 0) {
            this.paymentDisabled = true; 
          }
          this.loaded = true
          this.spinnerService.setSpinnerVisibility(false);
        },
        error: err => {
          this.spinnerService.setSpinnerVisibility(false);
          let messageData: GenericMessage = { messageText: this.translate.instant('common.network_error'), messageType: 'ERROR' }
          const dialogRef = this.errorDialog.open(GenericMessageDialogComponent, {
            disableClose: true,
            hasBackdrop: true,
            width: "85vw",
            data: messageData
          })
        }
      })
    })


  }
  ngOnDestroy(): void {
    this.loadBalance$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================

  forwardClick() {

    if (this.sumDeposit > 0 && this.startBillingRespose.CanBePayedWithDeposit) {
      this.dialog.open(YesNoDialogComponent, {
        disableClose: true,
        hasBackdrop: true,
        width: "85vw",
        data: { messageText: this.translate.instant('welcome.use_deposit'), messageType: 'INFORMATION' }
      }).afterClosed().subscribe(useDeposit => {
          SessionStorage.UseDeposit = useDeposit ? "y":"n"
          this.router.navigateByUrl('/invoicedetails')
      })
  } else {
    this.router.navigateByUrl('/invoicedetails') 
  }
}

  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  formattedDate(dateString: string): string {
    return dayjs(dateString).locale(SessionStorage.ActiveLanguage).format('ll');
  }
  // balanceItemStyle(rst: RoomStayCharge) {
  //   let styles = {};
  //   styles["color"] = rst.Price > 0 ? 'black' : 'red'
  //   return styles;
  // }

  //#endregion =================================================================================================================

}
