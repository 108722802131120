// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-right-1 {
  border-right: 1px solid white;
  height: 35px;
}

.hw-footer-elements {
  height: 35px;
  padding-top: 10px;
}

.text-white {
  color: white;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImZvb3Rlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLDZCQUFBO0VBQ0EsWUFBQTtBQUNGOztBQUVBO0VBQ0UsWUFBQTtFQUNBLGlCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxZQUFBO0FBQ0YiLCJmaWxlIjoiZm9vdGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmJvcmRlci1yaWdodC0xIHtcbiAgYm9yZGVyLXJpZ2h0OiAxcHggc29saWQgd2hpdGU7XG4gIGhlaWdodDogMzVweDtcbn1cblxuLmh3LWZvb3Rlci1lbGVtZW50cyB7XG4gIGhlaWdodDogMzVweDtcbiAgcGFkZGluZy10b3A6IDEwcHg7XG59XG5cbi50ZXh0LXdoaXRlIHtcbiAgY29sb3I6IHdoaXRlO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/components/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;AACA,wjBAAwjB","sourcesContent":[".border-right-1 {\n  border-right: 1px solid white;\n  height: 35px;\n}\n\n.hw-footer-elements {\n  height: 35px;\n  padding-top: 10px;\n}\n\n.text-white {\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
