// Angular
import { style } from '@angular/animations';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild, Renderer2, Input, ElementRef, AfterViewInit, Output, EventEmitter, Sanitizer } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// Hostware
import { BottomBarService } from '@app/services/bottom-bar.service';
import { HwComputerVisionService } from '@app/services/hw-computer-vision.service';
import { ImageCaptureService } from '@app/services/image-capture-service';
import { SpinnerService } from '@app/services/spinner.service';

import { TraceService } from '@app/services/trace.service';
import { CaptureMode } from '@app/types/Types';

import { GenericMessageDialogComponent } from '@app/dialogs/generic-message-dialog/generic-message-dialog.component';
import { GenericMessage } from '@app/interfaces/generic-message';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'image-capture',
  templateUrl: './image-capture.component.html',
  styleUrls: ['./image-capture.component.scss']
})
export class ImageCaptureComponent implements OnInit, AfterViewInit {

  @ViewChild('photoinput') photoInput: ElementRef;
  @ViewChild('captureCanvas', { static: true }) captureCanvas: ElementRef;
  @ViewChild('previewCanvas', { static: true }) previewCanvas: ElementRef;
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  @ViewChild('back', { static: true }) back: ElementRef;

  @ViewChild('previewImg', { static: true }) previewImg: ElementRef;


  @Input()
  captureMode: CaptureMode = 'DOCUMENT';

  @Input()
  imageWidth: number;

  captureContext: CanvasRenderingContext2D;
  previewContext: CanvasRenderingContext2D;

  previewImageVisible: boolean = true;

  backImagePath;
  landscape: boolean;
  landscapeString: string = "hello"
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;
  screenRatio = this.screenHeight / this.screenWidth;
  spinnerVisible: boolean;
  capturedImage = new Image();
  capturedFile: File;
  imageRatio: number;
  approveLineVisible: boolean;



  constructor(
    private breakPointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private imageCaptureService: ImageCaptureService,
    private bottomBarService: BottomBarService,
    private spinnerService: SpinnerService,
    private translate: TranslateService

  ) { }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit(): void {
    this.bottomBarService.setBottomBarVisibility(false)
    this.breakPointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait

    ]).subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.HandsetLandscape]) {
        this.landscape = true;
        this.landscapeString = "landscape";
      }
      if (state.breakpoints[Breakpoints.HandsetPortrait]) {
        this.landscape = false;
        this.landscapeString = "portrait"
      }
    })

    this.imageCaptureService.startCapture.subscribe(result => {

      this.photoInput.nativeElement.click();
    })
    this.spinnerService.spinnerVisibilityChanged.subscribe(event => {
      this.spinnerVisible = event;
    })
  }
  ngAfterViewInit(): void {
    this.captureContext = this.captureCanvas.nativeElement.getContext("2d");
    this.previewContext = this.previewCanvas.nativeElement.getContext("2d");

  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  async capture() {
    this.approveLineVisible = false;
    this.capturedImage = new Image();
    let fl: FileList = this.photoInput.nativeElement.files;
    this.capturedImage.onload = () => this.drawImageScaled(this.capturedImage);
    if (fl) {
      this.capturedImage.src = URL.createObjectURL(fl[0])
      this.capturedFile = fl[0];
    }
    //this.captureCanvas.nativeElement.style.visibility = "hidden"

  }



  private drawImageScaled(img: any): void {
    let imageRatio: number = 1
    imageRatio = img.height / img.width;
    if (img.width > img.height) {
      let messageData: GenericMessage = { messageText: this.translate.instant('photo.rotate_pls'), messageType: 'ERROR' }
      const dialogRef = this.dialog.open(GenericMessageDialogComponent, {
        disableClose: true,
        hasBackdrop: true,
        width: "85vw",
        data: messageData
      })
      return;
    }
    this.previewCanvas.nativeElement.style.visibility = 'visible';

    this.imageRatio = imageRatio
    if (img.width < 800) {
      this.imageWidth = img.width;
    } else {
      this.imageWidth = 800;
    }

    let ratio: number;
    const width = this.wrapper.nativeElement.clientWidth;
    const height = this.wrapper.nativeElement.clientHeight;
    const hRatio = width / img.width;
    const vRatio = height / img.height;
    ratio = Math.min(hRatio, vRatio);
    if (ratio > 1) {
      ratio = 1;
    }
    this.captureCanvas.nativeElement.width = this.imageWidth //img.width * ratio;
    this.captureCanvas.nativeElement.height = this.imageWidth * imageRatio//img.height * ratio;
    this.previewCanvas.nativeElement.width = width;
    this.previewCanvas.nativeElement.height = width * imageRatio
    this.captureContext.clearRect(0, 0, width, height);
    this.captureContext.drawImage(img, 0, 0, img.width, img.height, 0, 0, this.imageWidth, this.imageWidth * imageRatio);
    this.previewContext.clearRect(0, 0, this.imageWidth, this.imageWidth * imageRatio)
    this.previewContext.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, width * imageRatio)

    const dataUrl = this.captureCanvas.nativeElement.toDataURL("image/jpeg");
    const fullQuality = this.captureCanvas.nativeElement.toDataURL("image/jpeg").split(",")[1];

    // const base64Img = this.getBase64StringFromDataURL(dataUrl);
    this.imageCaptureService.imageCaptured.emit(fullQuality)
    this.previewImageVisible = true;
    this.approveLineVisible = true;
  }



  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  getBase64StringFromDataURL(dataURL: string): string {
    return dataURL.replace('data:', '').replace(/^.+,/, '')
  }



  hideResult() {
    // document.getElementById("previewCanvas").style.visibility = "hidden"
  }

  portraitStyle(landscape: boolean) {
    let styles = {};
    if (landscape) {
      styles["visibility"] = "hidden";
    } else {
      styles["visibility"] = "visible";
    }
    return styles;
  }

  landscapeStyle(landscape: boolean) {
    let styles = {};
    if (!landscape) {
      styles["visibility"] = "hidden";
    } else {
      styles["visibility"] = "visible";
    }
    return styles;
  }

  expoStyle() {
    let styles = {};
    if (this.spinnerVisible) {
      styles["pointer-events"] = "none"
      styles["color"] = "#ebd8d8"

    } else {
      styles["pointer-events"] = "auto"
      styles["color"] = "red"
    }
    return styles;

  }

  wrapperStyle() {
    const width = this.screenWidth;
    const height = this.screenWidth * this.imageRatio;
    let styles = {};
    styles["position"] = "absolute"
    styles["width"] = width + "px"
    styles["height"] = height + "px"
    styles["top"] = "170px"
    return styles;
  }
  declineImage() {
    this.approveLineVisible = false;
    this.previewImageVisible = false;
    this.previewCanvas.nativeElement.style.visibility = 'hidden';
  }
  approveImage() {
    this.approveLineVisible = false;
    const dataUrl = this.captureCanvas.nativeElement.toDataURL("image/jpeg");
    const base64Img = this.getBase64StringFromDataURL(dataUrl);
    this.imageCaptureService.imageApproved.emit(base64Img)
    this.previewCanvas.nativeElement.style.visibility = 'hidden';
  }
  //#endregion =================================================================================================================

}
