// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mdc-dialog__actions {
  display: contents;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInllcy1uby1kaWFsb2cuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxpQkFBQTtBQUNKIiwiZmlsZSI6Inllcy1uby1kaWFsb2cuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubWRjLWRpYWxvZ19fYWN0aW9ucyB7XG4gICAgZGlzcGxheTogY29udGVudHM7XG59Il19 */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/dialogs/yes-no-dialog/yes-no-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AACA,4UAA4U","sourcesContent":[".mdc-dialog__actions {\n    display: contents;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
