import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HeaderService } from '@app/services/header.service';

@Component({
  selector: 'app-show-fill-form-message',
  templateUrl: './show-fill-form-message.component.html',
  styleUrls: ['./show-fill-form-message.component.scss']
})
export class ShowFillFormMessageComponent implements OnInit {

  queryParams: Params;  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService

  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.queryParams=params;
      this.headerService.setNavigationVisibility(true);
    })
  }
  startClick() {}
}
