import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'under14-info',
  templateUrl: './under14-info.component.html',
  styleUrls: ['./under14-info.component.scss']
})
export class Under14InfoComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  forwardClick() {
    this.router.navigateByUrl('/vizadata')
  }
}
