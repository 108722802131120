// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  width: 100vw;
  padding-top: 3rem;
}

.wrapper {
  text-align: center;
}

mat-icon {
  transform: scale(2);
  color: red;
}

.button-ok {
  padding-top: 3rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNpbXBsZS1wYXltZW50LWNhbmNlbC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLFlBQUE7RUFDQSxpQkFBQTtBQUNKOztBQUVBO0VBQ0ksa0JBQUE7QUFDSjs7QUFFQTtFQUNJLG1CQUFBO0VBQ0EsVUFBQTtBQUNKOztBQUVBO0VBQ0ksaUJBQUE7QUFDSiIsImZpbGUiOiJzaW1wbGUtcGF5bWVudC1jYW5jZWwuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuaGVhZGVyLWNvbnRhaW5lciB7XG4gICAgd2lkdGg6IDEwMHZ3O1xuICAgIHBhZGRpbmctdG9wOiAzcmVtO1xufVxuXG4ud3JhcHBlciB7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xufVxuXG5tYXQtaWNvbiB7XG4gICAgdHJhbnNmb3JtOiBzY2FsZSgyKTtcbiAgICBjb2xvcjogcmVkO1xufVxuXG4uYnV0dG9uLW9rIHtcbiAgICBwYWRkaW5nLXRvcDogM3JlbTtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/pages/simple-payment-cancel/simple-payment-cancel.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,UAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;AACA,4qBAA4qB","sourcesContent":[".header-container {\n    width: 100vw;\n    padding-top: 3rem;\n}\n\n.wrapper {\n    text-align: center;\n}\n\nmat-icon {\n    transform: scale(2);\n    color: red;\n}\n\n.button-ok {\n    padding-top: 3rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
