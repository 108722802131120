// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-selection-wrapper {
  top: 50%;
  left: 50%;
}

.title {
  font-size: 21px;
  font-weight: 900;
}

.language-selection-image {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border-radius: 0 0 30px 30px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImxhbmd1YWdlLXNlbGVjdGlvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUdFLFFBQUE7RUFDQSxTQUFBO0FBREY7O0FBSUE7RUFDRSxlQUFBO0VBQ0EsZ0JBQUE7QUFERjs7QUFJQTtFQUNFLFdBQUE7RUFDQSxlQUFBO0VBQ0EsZUFBQTtFQUNBLDRCQUFBO0FBREYiLCJmaWxlIjoibGFuZ3VhZ2Utc2VsZWN0aW9uLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmxhbmd1YWdlLXNlbGVjdGlvbi13cmFwcGVyIHtcbiAgLy8gd2lkdGg6IDEwMCU7XG4gIC8vIGhlaWdodDogMTAwJTtcbiAgdG9wOiA1MCU7XG4gIGxlZnQ6IDUwJTtcbn1cblxuLnRpdGxlIHtcbiAgZm9udC1zaXplOiAyMXB4O1xuICBmb250LXdlaWdodDogOTAwO1xufVxuXG4ubGFuZ3VhZ2Utc2VsZWN0aW9uLWltYWdlIHtcbiAgd2lkdGg6IDEwMCU7XG4gIG1heC13aWR0aDogMTAwJTtcbiAgbWluLXdpZHRoOiAxMDAlO1xuICBib3JkZXItcmFkaXVzOiAwICAwIDMwcHggMzBweDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/pages/language-selection/language-selection.component.scss"],"names":[],"mappings":"AAAA;EAGE,QAAA;EACA,SAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;AADF;;AAIA;EACE,WAAA;EACA,eAAA;EACA,eAAA;EACA,4BAAA;AADF;AACA,wwBAAwwB","sourcesContent":[".language-selection-wrapper {\n  // width: 100%;\n  // height: 100%;\n  top: 50%;\n  left: 50%;\n}\n\n.title {\n  font-size: 21px;\n  font-weight: 900;\n}\n\n.language-selection-image {\n  width: 100%;\n  max-width: 100%;\n  min-width: 100%;\n  border-radius: 0  0 30px 30px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
