// Angular
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioGroup } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GenericMessageDialogComponent } from '@app/dialogs/generic-message-dialog/generic-message-dialog.component';

// 3rdparty

// Hostware
import { Room } from '@app/dto/ReservationInfo';
import SessionStorage from '@app/helpers/SessionStorage';
import { GenericMessage } from '@app/interfaces/generic-message';
import { BottomBarService } from '@app/services/bottom-bar.service';
import { HeaderService } from '@app/services/header.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rooms-and-emails',
  templateUrl: './rooms-and-emails.component.html',
  styleUrls: ['./rooms-and-emails.component.scss']
})
export class RoomsAndEmailsComponent implements OnInit,OnDestroy {

  @ViewChild('radioGroup') radioGroup: MatRadioGroup;
  queryParams: Params;
  editorForm : FormGroup;
  loaded: boolean = false
  selectedRoom: string;
  rooms = SessionStorage.ReservationInfo.Rooms;
  extendedRooms: RoomExtended[] = [];
  tableColumnFieldNames: string[] = [
    'select'
  ];  
  selection = new SelectionModel<RoomExtended>(false,[]);
  dataSource = new  MatTableDataSource<RoomExtended>(this.extendedRooms);
  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private bottomBarService: BottomBarService,
    private headerService: HeaderService
  ) {

    this.editorForm = this.fb.group({
      options:[]
    })
   }




  //#region =============== Angular Lifecyle events ============================================================================
  
  ngOnInit() {
    this.bottomBarService.setBottomBarVisibility(false);
    this.headerService.setNavigationVisibility(true);
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;
    })
    
    for( let i=0;i<this.rooms.length;i++) {
      let ex = new RoomExtended();
      ex.RoomId = this.rooms[i].RoomId;
      ex.TypeName = this.rooms[i].TypeName;
      ex.Guests = this.rooms[i].Guests;
      this.extendedRooms.push(ex)
      this.editorForm.addControl('email_' + i, new FormControl('', [Validators.required, Validators.email]))
      this.editorForm.addControl('name_'+ i, new FormControl('', [Validators.required,Validators.minLength(4)]))
    }
    this.dataSource = new  MatTableDataSource<RoomExtended>(this.extendedRooms)
    this.loaded = true;
    this.editorForm.controls["options"].setValue(this.extendedRooms[0].RoomId)
  }
  ngOnDestroy(): void {
    
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
   //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  guestNumber( room: RoomExtended) :number {
    return room.Guests.length
  }
  //#endregion =================================================================================================================


  forwardClick() {

    let messageData: GenericMessage = {messageText:this.translate.instant('rooms_and_emails.emails_sent'), messageType: 'INFORMATION'}
    const dialogRef = this.dialog.open(GenericMessageDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "85vw",
      data: messageData
    })
    
    // console.log(this.dataSource.data)
    // console.log(this.selectedRoom);

    //    this.router.navigate(["/photoguide"], { queryParams: this.queryParams })
  }
  
}

class RoomExtended extends Room {
  Name: string;
  Email: string;
}
