import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IdClassifierResult } from '@app/dto/IdClassifierResult';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({providedIn:"root"})
export class HwComputerVisionService {

 
constructor(private http: HttpClient) { 

}


idClassifier(base64Img: string) {
  const header = new HttpHeaders().set("Content-Type","application/json");
  return this.http.post(environment.hwComputerVisionUrl + "/idclassifier" ,{'faceImage': base64Img},{headers: header}) 
  // const header = new HttpHeaders().set("Content-Type","text/plain");
  // return this.http.post<IdClassifierResult>(environment.hwComputerVisionUrl +"/idclassifier",base64Img,{headers: header})
}

faceMatch(faceImageAsBase64: string, selfieImageAsBase64:string) {
  const header = new HttpHeaders().set("Content-Type","application/json");
  return this.http.post(environment.hwComputerVisionUrl + "/facematch" ,{'faceImage': faceImageAsBase64, 'selfie':selfieImageAsBase64},{headers: header}) 

}

hello() {
  return this.http.get(environment.hwComputerVisionUrl + "/hello")
}

}
