import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import ChatVisibilityHelper from '@app/helpers/ChatvisibilityHelper';
import SessionStorage from '@app/helpers/SessionStorage';
import { BottomBarService } from '@app/services/bottom-bar.service';
import { HeaderService } from '@app/services/header.service';


@Component({
  selector: 'app-age-selection',
  templateUrl: './age-selection.component.html',
  styleUrls: ['./age-selection.component.scss']
})
export class AgeSelectionComponent implements OnInit {


  queryParams: Params;
  editorForm : FormGroup;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private bottomBarService: BottomBarService,
    private headerService: HeaderService
  
    ) { 

      this.editorForm = this.fb.group({
        selectedAge: new FormControl('o14')
      })
    }

  ngOnInit() {  
    this.bottomBarService.setBottomBarVisibility(true);
    this.headerService.setNavigationVisibility(true);
    this.activatedRoute.queryParams.subscribe(params=> {
      this.queryParams=params;
    })
  }  
  forwardClick() {
     if(this.editorForm.controls["selectedAge"].value == "o14") {
      SessionStorage.GuestAge = 'o14';
      this.router.navigate(["/photo"],{queryParams:this.queryParams})
    }
    else {
      SessionStorage.GuestAge = 'u14';
      this.router.navigate(["/vizadata"],{queryParams:this.queryParams})
    }
    
  }


  chatIconStyle() {
    let styles = {};
    styles = ChatVisibilityHelper.ChatVisibleStyle
    return  styles
  }
}
