import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import SessionStorage from '@app/helpers/SessionStorage';
import { BigFish } from '@app/interfaces/BigFish';
import { BigFishPayMode } from '@app/interfaces/BigFishPayMode';
import { ExcludedServicesInformationDialogComponent } from '../excluded-services-information-dialog/excluded-services-information-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'bigfish-payment-dialog',
  templateUrl: './bigfish-payment-dialog.component.html',
  styleUrls: ['./bigfish-payment-dialog.component.scss']
})
export class BigfishPaymentDialogComponent implements OnInit {

  payModes: BigFishPayMode[] = [];

  creditCardModes: BigFishPayMode[] = [];
  szepCardModes: BigFishPayMode[] = []
  editorForm: FormGroup
  
  constructor(
    private szepCardAlertDialog : MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private dialogRef: MatDialogRef<BigfishPaymentDialogComponent>, @Inject(MAT_DIALOG_DATA)
    private data: BigFishPayMode[]
    
  ) {

    this.payModes = data["Paymodes"]
    this.payModes.forEach(payMode => {
      if (payMode.Type == "CREDITCARD") {
        this.creditCardModes.push(payMode)
      } else {
        this.szepCardModes.push(payMode)
      }
    })

    this.editorForm = fb.group({
      creditCardOptions: new FormControl(this.creditCardModes[0].ShortName),
      szepCardOptions: new FormControl('')
    })
  }
  ngOnInit(): void {
    this.editorForm.controls["creditCardOptions"].patchValue(this.creditCardModes[0].ShortName)
    if (SessionStorage.HasOnlyExludedServices == 'y') {
      this.editorForm.controls["szepCardOptions"].disable()
    }
  }


  creditCardOptionsChanged() {
    this.editorForm.controls["szepCardOptions"].patchValue("")
  }
  szepCardOptionsChanged() {
    this.editorForm.controls["creditCardOptions"].patchValue("")
  }

  cancel() { 
    this.dialogRef.close(undefined)
  }

  submit() {
    let isContinue: boolean;
    if (this.editorForm.controls["creditCardOptions"].value !== '') {
      this.dialogRef.close("OTP-" + this.editorForm.controls["creditCardOptions"].value)
    } else {  // szép kártyát választott
      if(SessionStorage.HasExludedService === 'y') {
        this.szepCardAlertDialog.open(ExcludedServicesInformationDialogComponent).afterClosed().subscribe(result=> {
          isContinue = result ? true : false 
          if(isContinue) {
            const paymentOption = this.payModes.find(item=>item.ShortName == this.editorForm.controls["szepCardOptions"].value) 
            SessionStorage.SelectedPaymentType == paymentOption.Type;
            this.dialogRef.close("BIGFISH-" + this.editorForm.controls["szepCardOptions"].value)
          } else {
            this.router.navigateByUrl('/welcome')
          } 
        })
      }
    }
  }
}

export class BigFishWrapper {
  BigFishData: BigFishPayMode[]
  CanBePayedWithSzepCard: boolean
}