
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import SimpleCallBackUrlHelper from '@app/helpers/SimpleCallBackUrlHelper';

@Component({
  selector: 'simple-payment-success',
  templateUrl: './simple-payment-success.component.html',
  styleUrls: ['./simple-payment-success.component.scss']
})
export class SimplePaymentSuccessComponent implements OnInit {
  
  
  transactionId : string;

  constructor( private router: Router,
                private route: ActivatedRoute


  ) {
     
    
  }
  ngOnInit(): void {
     let paramR = this.route.snapshot.queryParamMap.get('r');
     const result = SimpleCallBackUrlHelper.GetResultR(paramR);
     this.transactionId = result.t;
  }
  backClick() {
    // clear sessionstore data
    // navigate back
    this.router.navigateByUrl('/checkinstatus')
  }


  
}

// localhost:4200/simplepaymentsuccess/?r=eyJyIjowLCJ0Ijo5OTg0NDk0MiwiZSI6IlNVQ0NFU1MiLCJtIjoiUFVCTElDVEVTVEhVRiIsIm8iOiIxMDEwMTA1MTU2ODAyOTI0ODI2MDAifQ%3D%3D&s=El%2Fnvex9TjgjuORI63gEu5I5miGo4CSAD5lmEpKIxp7WuVRq6bBeh1QdyEvVGSsi


