import { Component } from '@angular/core';

@Component({
  selector: 'simple-conditions-approval',
  templateUrl: './simple-conditions-approval.component.html',
  styleUrls: ['./simple-conditions-approval.component.scss']
})
export class SimpleConditionsApprovalComponent {

}
