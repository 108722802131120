
// Angular
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

// 3rdparty
import dayjs from 'dayjs'
// Hostware

import SessionStorage from '@app/helpers/SessionStorage';
import { HeaderService } from '@app/services/header.service';
import { BottomBarService } from '@app/services/bottom-bar.service';
import { YesNoDialogComponent } from '@app/dialogs/yes-no-dialog/yes-no-dialog.component';
import LicenceOptions from '@app/helpers/Options';
import { GuestStayStatus } from '@app/types/Types';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  checkInDate: string;
  checkOutDate: string;
  hasExiLicence = LicenceOptions.HasExiLicence
  hasExoLicence = LicenceOptions.HasExoLicence
  

  constructor(
    private router:Router,
    private headerService: HeaderService,
    private bottomBarService: BottomBarService,
    private dialog: MatDialog

  ) { }
  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit() {
    this.checkInDate = dayjs(SessionStorage.ReservationInfo.Rooms[0].Guests[0].Arrival).locale(SessionStorage.ActiveLanguage).format('ll');
    this.checkOutDate = dayjs(SessionStorage.ReservationInfo.Rooms[0].Guests[0].Departure).locale(SessionStorage.ActiveLanguage).format('ll');
    this.headerService.setNavigationVisibility(true);
  }
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
   checkInClick() {
    this.bottomBarService.setBottomBarVisibility(false);
    this.router.navigateByUrl('/checkinstatus')
  }

  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================
  guestName() {
    return SessionStorage.ReservationInfo.Rooms[0].Guests[0].Name;
  }
  //#endregion =================================================================================================================

  guests() {
    return SessionStorage.guestNumber();
  }

  nights() {
    return SessionStorage.nights();
  }


}
/*
  payment gomb az alábbi esetben lehet aktív:

  1. Legalább 1 fő (a szobafőnök) az alábbi státuszok valamelyikével rendelkezik: VIZA_VALID, READY_TO_CHECKIN, CHECKED_IN
  2. Csak az érkezés napján lehet aktív
  3. Van a szobaszámlán terhelése
*/