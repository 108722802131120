// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  width: 100vw;
  padding-top: 3rem;
}

.wrapper {
  text-align: center;
}

.button-ok {
  padding-top: 3rem;
}

.timeout-icon {
  color: orange;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNpbXBsZS1wYXltZW50LXRpbWVvdXQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxZQUFBO0VBQ0EsaUJBQUE7QUFDSjs7QUFFQTtFQUNJLGtCQUFBO0FBQ0o7O0FBT0E7RUFDSSxpQkFBQTtBQUpKOztBQU9BO0VBQ0ksYUFBQTtBQUpKIiwiZmlsZSI6InNpbXBsZS1wYXltZW50LXRpbWVvdXQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuaGVhZGVyLWNvbnRhaW5lciB7XG4gICAgd2lkdGg6IDEwMHZ3O1xuICAgIHBhZGRpbmctdG9wOiAzcmVtO1xufVxuXG4ud3JhcHBlciB7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xufVxuXG4vLyBtYXQtaWNvbiB7XG4vLyAgICAgdHJhbnNmb3JtOiBzY2FsZSgyKTtcbi8vICAgICBjb2xvcjogcmdiKDE5OSwgMTk5LCA0OSk7XG4vLyB9XG5cbi5idXR0b24tb2sge1xuICAgIHBhZGRpbmctdG9wOiAzcmVtO1xufVxuXG4udGltZW91dC1pY29uIHtcbiAgICBjb2xvcjogb3JhbmdlO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/pages/simple-payment-timeout/simple-payment-timeout.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAOA;EACI,iBAAA;AAJJ;;AAOA;EACI,aAAA;AAJJ;AACA,4vBAA4vB","sourcesContent":[".header-container {\n    width: 100vw;\n    padding-top: 3rem;\n}\n\n.wrapper {\n    text-align: center;\n}\n\n// mat-icon {\n//     transform: scale(2);\n//     color: rgb(199, 199, 49);\n// }\n\n.button-ok {\n    padding-top: 3rem;\n}\n\n.timeout-icon {\n    color: orange;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
