import { Injectable, Output, EventEmitter } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class HeaderService {

  @Output() navigationVisible = new EventEmitter<boolean>()


  setNavigationVisibility(visibility: boolean) {
    this.navigationVisible.emit(visibility);
  }

  constructor(

  ) { }


}
