import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import SessionStorage from '@app/helpers/SessionStorage';
import { Observable } from 'rxjs';


@Injectable({providedIn:'root'})
export class HwAuthInterceptorService implements HttpInterceptor {

  constructor(
    protected router: Router,
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if( req.url.startsWith('/assets')) {
      return next.handle(req);
    }
    const token = req.url.includes('CreateGuestToken') ? '':  SessionStorage.GuestData.Token;
    const cloned = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    return next.handle(cloned);
  }

}
