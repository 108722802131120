import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({'providedIn':'root'})
export class BottomBarService {

constructor() { }

@Output() bottomBarVisibilyChanged = new EventEmitter<boolean>()

public setBottomBarVisibility( visibility: boolean) {
    this.bottomBarVisibilyChanged.emit(visibility)
}

}
