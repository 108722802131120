import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PayStartRq } from '@app/dto/PayStartRequest';
import { StartBillingResponse } from '@app/interfaces/StartBillingResponse';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(private http: HttpClient) { }


  StartBilling( guestStayUuid: string ,  lang: string, onlyView:boolean) {
    return this.http.get(`${environment.exiBackendUrl}/api/exi/billing/startbilling/${guestStayUuid}/${lang}/${onlyView}` )
  }
 

  StartPayment(payStartRq : PayStartRq) {
    const headers = new HttpHeaders().set("Content-Type","application/json");
    return this.http.post(environment.exiBackendUrl+"/api/exi/billing/StartPayment",payStartRq,{headers})
  }

}
