import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InvitationService } from '../../../../../froexishared/src/services/invitation.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  navigationEnabled: boolean = true;
  isDefLangEn: boolean = false
  constructor(
    private invitationService: InvitationService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.loadAll()
  }

  navigateToAszf() {
    let currLang = this.translate.getLangs();
    if (currLang[0] === 'hu') window.open(sessionStorage.getItem('huPrivacyPolicyUrl'), "_blank");
    if (currLang[0] === 'en') window.open(sessionStorage.getItem('enPrivacyPolicyUrl'), "_blank");
    if (currLang[0] === 'de') window.open(sessionStorage.getItem('dePrivacyPolicyUrl'), "_blank");
    if (currLang[0] === 'fr') window.open(sessionStorage.getItem('frPrivacyPolicyUrl'), "_blank");
    if (currLang[0] === 'sk') window.open(sessionStorage.getItem('skPrivacyPolicyUrl'), "_blank");

    if (currLang[0] !== 'hu' && currLang[0] !== 'en' && currLang[0] !== 'de' && currLang[0] !== 'fr' && currLang[0] !== 'sk') window.open(sessionStorage.getItem('enPrivacyPolicyUrl'), "_blank");
  }

  navigateToGDPR() {
    let currLang = this.translate.getLangs();
    if (currLang[0] === 'hu') window.open(sessionStorage.getItem('huTermconditionsUrl'), "_blank");
    if (currLang[0] === 'en') window.open(sessionStorage.getItem('enTermconditionsUrl'), "_blank");
    if (currLang[0] === 'de') window.open(sessionStorage.getItem('deTermconditionsUrl'), "_blank");
    if (currLang[0] === 'fr') window.open(sessionStorage.getItem('frTermconditionsUrl'), "_blank");
    if (currLang[0] === 'sk') window.open(sessionStorage.getItem('skTermconditionsUrl'), "_blank");

    if (currLang[0] !== 'hu' && currLang[0] !== 'en' && currLang[0] !== 'de' && currLang[0] !== 'fr' && currLang[0] !== 'sk') window.open(sessionStorage.getItem('enPrivacyPolicyUrl'), "_blank");
  }

  loadAll() {
    let rinf = JSON.parse(sessionStorage.getItem('rinf'))
    this.invitationService.LoadInvitations(rinf.Subscriber, rinf.Hotelid).subscribe(response => {
      Object.keys(response).forEach((key) => {
        switch (response[key].Lang) {
          case 'hu':
            sessionStorage.setItem('huPrivacyPolicyUrl', response[key].PrivacyPolicyUrl)
            sessionStorage.setItem('huTermconditionsUrl', response[key].TermconditionsUrl)
            break;
          case 'en':
            sessionStorage.setItem('enPrivacyPolicyUrl', response[key].PrivacyPolicyUrl)
            sessionStorage.setItem('enTermconditionsUrl', response[key].TermconditionsUrl)
            break;
          case 'de':
            sessionStorage.setItem('dePrivacyPolicyUrl', response[key].PrivacyPolicyUrl)
            sessionStorage.setItem('deTermconditionsUrl', response[key].TermconditionsUrl)
            break;
          case 'fr':
            sessionStorage.setItem('frPrivacyPolicyUrl', response[key].PrivacyPolicyUrl)
            sessionStorage.setItem('frTermconditionsUrl', response[key].TermconditionsUrl)
            break;
          case 'sk':
            sessionStorage.setItem('skPrivacyPolicyUrl', response[key].PrivacyPolicyUrl)
            sessionStorage.setItem('skTermconditionsUrl', response[key].TermconditionsUrl)
            break;

          default:
            break;
        }
      })
    })
  }
}