// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.material-icons {
  font-size: 30px;
  height: 30px;
  width: 30px;
}

.icon-align {
  display: block;
  margin: auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNob3ctY2hlY2staW4tc3RlcHMuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSSxlQUFBO0VBQ0EsWUFBQTtFQUNBLFdBQUE7QUFDSjs7QUFFRTtFQUNFLGNBQUE7RUFDQSxZQUFBO0FBQ0oiLCJmaWxlIjoic2hvdy1jaGVjay1pbi1zdGVwcy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5tYXRlcmlhbC1pY29ucyB7XG4gICAgZm9udC1zaXplOiAzMHB4O1xuICAgIGhlaWdodDogMzBweDtcbiAgICB3aWR0aDogMzBweDtcbiAgfVxuXG4gIC5pY29uLWFsaWduIHtcbiAgICBkaXNwbGF5OiBibG9jaztcbiAgICBtYXJnaW46IGF1dG87XG4gIH0iXX0= */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/pages/show-check-in-steps/show-check-in-steps.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,WAAA;AACJ;;AAEE;EACE,cAAA;EACA,YAAA;AACJ;AACA,giBAAgiB","sourcesContent":[".material-icons {\n    font-size: 30px;\n    height: 30px;\n    width: 30px;\n  }\n\n  .icon-align {\n    display: block;\n    margin: auto;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
