// Angular
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// 3rdParty 
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
// Hostware 
import { BottomBarService } from '@app/services/bottom-bar.service';
import { ImageCaptureService } from '@app/services/image-capture-service';
import { SpinnerService } from '@app/services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { style } from '@angular/animations';
@AutoUnsubscribe()
@Component({
  selector: 'image-capture2',
  templateUrl: './image-capture2.component.html',
  styleUrls: ['./image-capture2.component.scss']
})
export class ImageCapture2Component implements OnInit, OnDestroy {

  @Input() captureMode: string
  @Input() imageWidth: number;

  @ViewChild('captureCanvas', { static: true }) captureCanvas: ElementRef;
  @ViewChild('previewCanvas', { static: true }) previewCanvas: ElementRef;
  
  previewImageVisible: boolean = true;
  approveLineVisible: boolean = false;
  spinnerVisible : boolean
  landscape: boolean;
  myVideo: any

  currentStream = null;
  facingMode: FacingModes;

  constructor(
    private breakPointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private imageCaptureService: ImageCaptureService,
    private bottomBarService: BottomBarService,
    private spinnerService : SpinnerService,
    private translate : TranslateService

  ) {


  }

  //#region =============== Angular Lifecyle events ============================================================================

  ngOnInit(): void {
    this.bottomBarService.setBottomBarVisibility(false);
    this.breakPointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait

    ]).subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.HandsetLandscape]) {
        this.landscape = true;
      }
      if (state.breakpoints[Breakpoints.HandsetPortrait]) {
        this.landscape = false;
      }
    })

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.myVideo = document.getElementById("myVideo")
      this.facingMode = FacingModes.Environment;
      this.startCamera(FacingModes.Environment)
    } else {
      alert("nincs kamera");
    }
  }
  ngOnDestroy(): void {
    this.stopCamera()
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================

  capture() {
    //const canvas=document.createElement('canvas');
    let ratio: number;   
    this.previewCanvas.nativeElement.width = this.myVideo.videoWidth;
    this.previewCanvas.nativeElement.height = this.myVideo.videoHeight;
    const ctx = this.previewCanvas.nativeElement.getContext('2d');
    
    ctx.drawImage(this.myVideo, 0, 0);
    const dataURL = this.previewCanvas.nativeElement.toDataURL('image/jpeg'); 
    const fullQuality = dataURL.replace(/^data:image\/jpeg;base64,/, '');
    this.imageCaptureService.imageCaptured.emit(fullQuality)
    this.previewImageVisible = true;
    this.approveLineVisible = true;
  }

  switchCamera() {
    if(this.facingMode == FacingModes.Environment) {
      this.facingMode = FacingModes.User
    }  else {
      this.facingMode = FacingModes.Environment
    }
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================

  declineImage() {
    this.approveLineVisible = false;
    this.previewImageVisible = false;
    //this.previewCanvas.nativeElement.style.visibility = 'hidden';
  }
  approveImage() {
    this.approveLineVisible = false;
    //const dataUrl = this.captureCanvas.nativeElement.toDataURL("image/jpeg");
    //const base64Img = this.getBase64StringFromDataURL(dataUrl);
    //this.imageCaptureService.imageApproved.emit(base64Img)
    //this.previewCanvas.nativeElement.style.visibility = 'hidden';
  }
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  startCamera(facingMode) {

    const constraints = { video: { facingMode: facingMode } };
    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => {
        this.currentStream = stream;
        console.log(this.myVideo);
        this.myVideo.srcObject = stream;
      })
      .catch(error => {
        alert("No camera access")
        console.error("Error accessing camera:", error)
      }
      );
  }
  stopCamera() {
    this.currentStream = null;
    console.log(this.myVideo);
    this.myVideo.srcObject = null;
  }

  landscapeStyle(landscape : boolean) {
    let styles={};
    if(!landscape) {
      styles["visibility"]="hidden";
    } else 
    {
      styles["visibility"]="visible";
    }
    return styles;
  }

  expoStyle() {
    let styles={};
    if(this.spinnerVisible) {
      styles["pointer-events"] = "none"
      styles["color"] = "#ebd8d8"
      
    } else {
      styles["pointer-events"] = "auto"
      styles["color"]="red"
    }
    return styles;

  }

  cameraStreamStyle() {
    let styles = {}
    styles["width"]="100%"
    styles["height"]="100%"
    if(this.approveLineVisible) {
      styles["visibility"]="hidden"
    } else {
      styles["visibility"]
    }
    return styles;
  }
  //#endregion =================================================================================================================



}

enum FacingModes {
  'Environment' = 'environment',
  'User' = 'user'
}
