// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-icon {
  color: red;
}

.success-icon {
  color: green;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJpZ2Zpc2gtcGF5bWVudC1oYW5kbGVyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsVUFBQTtBQUNGOztBQUVBO0VBQ0UsWUFBQTtBQUNGIiwiZmlsZSI6ImJpZ2Zpc2gtcGF5bWVudC1oYW5kbGVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmVycm9yLWljb24ge1xuICBjb2xvcjogcmVkO1xufVxuXG4uc3VjY2Vzcy1pY29uIHtcbiAgY29sb3I6IGdyZWVuO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/pages/bigfish-payment-handler/bigfish-payment-handler.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,YAAA;AACF;AACA,gaAAga","sourcesContent":[".error-icon {\n  color: red;\n}\n\n.success-icon {\n  color: green;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
