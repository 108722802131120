import { GuestStayStatus } from "@app/types/Types";

export class ReservationInfo {
  Subscriber : string;
  Hotelid : string;
  Hotel3pId: string;
  ReservationUuId: string;
  ReservationEmail: string;
  Invited : string;
  Rooms: Room[];
}

export class Room {
  RoomType : string;
  TypeName : string;
  RoomId:string
  Guests: RoomGuest[]
}

 export class RoomGuest {
     GuestStayUuId: string;
     Status : GuestStayStatus;
     Arrival : Date;
     Departure : string;   
     Name : string;
     Email : string;
     VizaData:string;
     InvitationEmail:string;
     FaceCheck:string
}
