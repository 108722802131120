import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericMessage } from '@app/interfaces/generic-message';
@Component({
  selector: 'generic-message-dialog',
  templateUrl: './generic-message-dialog.component.html',
  styleUrls: ['./generic-message-dialog.component.scss']
})
export class GenericMessageDialogComponent implements OnInit {

 
  genericMessage: GenericMessage;
  constructor(public dialogRef: MatDialogRef<GenericMessageDialogComponent>, @Inject(MAT_DIALOG_DATA) private message: GenericMessage) {
    this.genericMessage = message
   }

  ngOnInit() {
  }
  
  submit() {
    this.dialogRef.close(true );
  }
}
