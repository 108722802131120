
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import HwUtils from '@app/helpers/HwUtils';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
@Component({
  selector: 'date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: DateSelectorComponent
    }
  ]
})     
export class DateSelectorComponent implements OnInit , ControlValueAccessor{
  @ViewChild('year') yearCombo: MatSelect;
  @ViewChild('month') monthCombo: MatSelect;
  @ViewChild('day') dayCombo: MatSelect;

  dateValue: string;

  dateGroup: FormGroup;
  years: LabelValue[] = [];
  months: LabelValue[] = [];
  days: LabelValue[] = [];
  loaded: boolean;
  currYear: number = dayjs().year()
  
  onChangeSubs: Subscription[] = [];
  
 

  constructor(private fb: FormBuilder) {
    this.dateGroup = this.fb.group({
      Year: new FormControl('2000'),
      Month: new FormControl('01'),
      Day: new FormControl("01")
    })
  }

  /* ---- ControlValueAccessor implementation ---  */

  private onChange:  (value:string) => void
  private onTouched: () => void;
  
  writeValue(dateVal: string): void {
    this.dateValue = dateVal;
    if (dateVal == '') {
      
      this.dateGroup.controls["Year"].setValue(this.currYear);
      this.dateGroup.controls["Month"].setValue("01");
      for(var i = 1;i<=31; i++) {
        this.days.push({
          Label: HwUtils.leftPad(i,2).toString(),
          Value: HwUtils.leftPad(i,2).toString()
        })
      }
      this.dateGroup.controls["Day"].setValue("01");
    } else {
      let isNum = /^\d+$/.test(dateVal);
      if (isNum) {
        this.fillDaysCombo();
        const y = dateVal.substring(0, 2)
        let prefix= parseInt(y) > 23? '19' : '20';   // - ha az nagyobb mint 23, akkor az év 19xx  - ha kisebb, akkor az év 20xx
        this.dateGroup.controls["Year"].setValue(prefix+y)
        this.dateGroup.controls["Month"].setValue(dateVal.substring(2,4))
        this.dateGroup.controls["Day"].setValue(dateVal.substring(4,6))
      } else {
        this.dateGroup.controls["Year"].setValue('2000')
        this.fillDaysCombo();
        this.onChange(this.selectedValue())
      }
    }
   
  }
  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
   // throw new Error('Method not implemented.');
  }

  ngOnInit() {
   
    for (var i = 0; i < 120; i++) {
      this.years.push({
        Label: HwUtils.leftPad(this.currYear - i,2).toString(),
        Value: HwUtils.leftPad(this.currYear - i,2).toString()
      })
    }

    for(var i = 1;i<=12; i++) {
      this.months.push({
        Label: HwUtils.leftPad(i,2).toString(),
        Value: HwUtils.leftPad(i,2).toString()
      })
    }
    this.loaded = true;
  }

  fillMonthCombo() {
   
  }

  monthSelectionChanged() {
    this.fillDaysCombo();
    this.onChange(this.selectedValue())
  }
  yearSelectionChanged() {
    this.onChange(this.selectedValue())
  }

  daySelectionChanged() {
    this.onChange(this.selectedValue())
  }

  public selectedValue() : string {
    return this.dateGroup.controls["Year"].value+this.dateGroup.controls["Month"].value+this.dateGroup.controls["Day"].value;
  }

  public selectedDateValue() : string {
    return this.dateGroup.controls["Year"].value+"-" + this.dateGroup.controls["Month"].value +"-" +this.dateGroup.controls["Day"].value;
  }

  fillDaysCombo() {

    let daysInMonth : number;      
    const y = this.dateValue.substring(0, 2)
    const m = this.dateValue.substring(2, 4)
    const d = this.dateValue.substring(4, 6)
    // fill  days
    let prefix= parseInt(y) >= 0? '20' : '19';
    let fullDate = dayjs(prefix+"."+ y+"."+ m + "."+d)
    
    switch(m) {
      case "01":
      case "03":
      case "05":
      case "07":
      case "08":
      case "10":
      case "12":
        daysInMonth = 31;
        break;
      case "04":
      case "06":
      case "09":
      case "11":
        daysInMonth = 30;    
        break;
      case "02": 
        daysInMonth = fullDate.isLeapYear() ? 29 : 28
    }

    for(let i=1; i<=daysInMonth;i++) {
        this.days.push({
          Label: HwUtils.leftPad(i,2).toString(),
          Value: HwUtils.leftPad(i,2).toString()
        })
    }

  }

}

interface LabelValue {
  Label: string;
  Value: string;
}