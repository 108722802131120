import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VizaData } from '@app/interfaces/VizaData';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class VizaDataService {

  constructor(private http: HttpClient) {


  }
  SendVizaData(data: VizaData) {
    const headers = new HttpHeaders().set("Content-Type","application/json");
    return this.http.post(environment.exiBackendUrl+"/api/exi/viza/checkviza",data,{headers})
  }
}
