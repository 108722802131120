// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.room-row {
  background-color: #efefef;
  padding: 0.5em;
}

.guest-name-align {
  padding: 10px 0;
}

.completion-counter {
  color: #a2a2a2;
}

.material-icons {
  font-size: 30px;
  height: 30px;
  width: 30px;
}

.icon-align {
  display: block;
  margin: auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoZWNrLWluLXN0YXR1cy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLHlCQUFBO0VBQ0EsY0FBQTtBQUNGOztBQUVBO0VBQ0UsZUFBQTtBQUNGOztBQUVBO0VBQ0UsY0FBQTtBQUNGOztBQUVBO0VBQ0UsZUFBQTtFQUNBLFlBQUE7RUFDQSxXQUFBO0FBQ0Y7O0FBRUE7RUFNRSxjQUFBO0VBQ0EsWUFBQTtBQUpGIiwiZmlsZSI6ImNoZWNrLWluLXN0YXR1cy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5yb29tLXJvdyB7XG4gIGJhY2tncm91bmQtY29sb3I6ICNlZmVmZWY7XG4gIHBhZGRpbmc6IDAuNWVtO1xufVxuXG4uZ3Vlc3QtbmFtZS1hbGlnbiB7XG4gIHBhZGRpbmc6IDEwcHggMDtcbn1cblxuLmNvbXBsZXRpb24tY291bnRlciB7XG4gIGNvbG9yOiAjYTJhMmEyO1xufVxuXG4ubWF0ZXJpYWwtaWNvbnMge1xuICBmb250LXNpemU6IDMwcHg7XG4gIGhlaWdodDogMzBweDtcbiAgd2lkdGg6IDMwcHg7XG59XG5cbi5pY29uLWFsaWduIHtcbiAgZGlzcGxheTogYmxvY2s7XG4gIG1hcmdpbjogYXV0bztcbn1cblxuLmljb24tYWxpZ24ge1xuICBkaXNwbGF5OiBibG9jaztcbiAgbWFyZ2luOiBhdXRvO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./projects/froexi_customerfacing/src/app/pages/check-in-status/check-in-status.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EAME,cAAA;EACA,YAAA;AAJF;AACA,o4BAAo4B","sourcesContent":[".room-row {\n  background-color: #efefef;\n  padding: 0.5em;\n}\n\n.guest-name-align {\n  padding: 10px 0;\n}\n\n.completion-counter {\n  color: #a2a2a2;\n}\n\n.material-icons {\n  font-size: 30px;\n  height: 30px;\n  width: 30px;\n}\n\n.icon-align {\n  display: block;\n  margin: auto;\n}\n\n.icon-align {\n  display: block;\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
