import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import SessionStorage from '@app/helpers/SessionStorage';
import { HeaderService } from '@app/services/header.service';
import ChatVisibilityHelper from '@app/helpers/ChatvisibilityHelper';


@Component({
  selector: 'app-show-check-in-steps',
  templateUrl: './show-check-in-steps.component.html',
  styleUrls: ['./show-check-in-steps.component.scss']
})
export class ShowCheckInStepsComponent implements OnInit {
  queryParams: Params;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;
    })
    this.headerService.setNavigationVisibility(true);
  }

  startClick() {
    this.router.navigateByUrl('/checkinstatus')
    let  guest = SessionStorage.SelectedGuestData
    SessionStorage.MrzData = undefined;
    SessionStorage.FaceImageFromCard = '';
    SessionStorage.Selfie = '';
    this.router.navigateByUrl('/ageselection?GuestStayUuId=' + guest.GuestStayUuId)
    //this.router.navigateByUrl('/ageselection?GuestStayUuId=' + guest.GuestStayUuId);
  }


  chatIconStyle() {
    let styles = {};
    styles = ChatVisibilityHelper.ChatVisibleStyle
    return  styles
  }
}
