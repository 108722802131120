import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdditionalData } from '@app/dto/AdditionalData';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AdditionalDataService {
  constructor(private http: HttpClient) {
  }

  SendAdditionalData( data : AdditionalData) {
    const headers = new HttpHeaders().set("Content-Type","application/json");
    return this.http.post(environment.exiBackendUrl+"/api/exi/viza/AddGuestData",data,{headers})
  }
}