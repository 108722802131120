import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Invitation } from '@app/dto/Invitation';
import { RoomGuest } from '@app/dto/ReservationInfo';

@Component({
  selector: 'send-invitation-dialog',
  templateUrl: './send-invitation-dialog.component.html',
  styleUrls: ['./send-invitation-dialog.component.scss']
})
export class SendInvitationDialogComponent implements OnInit {

  _roomGuest: RoomGuest;
  constructor(
    private dialogRef: MatDialogRef<SendInvitationDialogComponent>, @Inject(MAT_DIALOG_DATA) private guest: RoomGuest,
    private fb: FormBuilder

  ) {
    this.editorForm = fb.group({
      Email: new FormControl("", Validators.compose([Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)])),
      Name: new FormControl('', [Validators.required])
    })
    this._roomGuest = guest
  }
  editorForm: FormGroup;
  ngOnInit() {

  }

  cancel() {
    this.dialogRef.close(undefined)
  }

  submit() {
    let roomGuest: RoomGuest = new RoomGuest();
    roomGuest.GuestStayUuId = this._roomGuest.GuestStayUuId;
    roomGuest.Name = this.editorForm.controls["Name"].value;
    roomGuest.InvitationEmail = this.editorForm.controls["Email"].value;
    this.dialogRef.close(roomGuest)
  }


}
