import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { ImageCaptureComponent } from './components/image-capture/image-capture.component';
import { LanguageSelectionComponent } from './pages/language-selection/language-selection.component';

import { PhotoComponent } from './pages/photo/photo.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { CheckInStatusComponent } from './pages/check-in-status/check-in-status.component';
import { AgeSelectionComponent } from './pages/age-selection/age-selection.component';
import { ShowCheckInStepsComponent } from './pages/show-check-in-steps/show-check-in-steps.component';
import { ShowFillFormMessageComponent } from './pages/show-fill-form-message/show-fill-form-message.component';
import { PhotoGuideComponent } from './pages/photo-guide/photo-guide.component';
import { RoomsAndEmailsComponent } from './pages/rooms-and-emails/rooms-and-emails.component';
import { IdVerificationOkComponent } from './pages/id-verification-ok/id-verification-ok.component';
import { VizaDataComponent } from './pages/viza-data/viza-data.component';
import { AdditionalDataComponent } from './pages/additional-data/additional-data.component';
import { Under14InfoComponent } from './pages/under14-info/under14-info.component';
import { BalanceComponent } from './pages/balance/balance.component';
import { InvoiceDetailsComponent } from './pages/invoice-details/invoice-details.component';
import { BillViewComponent } from './pages/bill-view/bill-view.component';
import { FakeExternalLinkComponent } from './pages/fake-external-link/fake-external-link.component';
import { SimplePaymentErrorComponent } from './pages/simple-payment-error/simple-payment-error.component';
import { SimplePaymentSuccessComponent } from './pages/simple-payment-success/simple-payment-success.component';
import { AppComponent } from './app.component';
import { SimplePaymentTimeoutComponent } from './pages/simple-payment-timeout/simple-payment-timeout.component';
import { SimplePaymentCancelComponent } from './pages/simple-payment-cancel/simple-payment-cancel.component';
import { BigfishPaymentHandlerComponent } from './pages/bigfish-payment-handler/bigfish-payment-handler.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  { path: '', component: AppComponent },
  { path: 'photo', component: PhotoComponent },
  { path: 'languageselection', component: LanguageSelectionComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'checkinstatus', component: CheckInStatusComponent },
  { path: 'ageselection', component: AgeSelectionComponent },
  { path: 'showcheckinsteps', component: ShowCheckInStepsComponent },
  { path: 'showfillformmessage', component: ShowFillFormMessageComponent },
  { path: 'photoguide', component: PhotoGuideComponent },
  { path: 'roomsandemails', component: RoomsAndEmailsComponent },
  { path: 'idverificationok', component: IdVerificationOkComponent },
  { path: 'vizadata', component: VizaDataComponent },
  { path: 'additionaldata', component: AdditionalDataComponent },
  { path: 'under14info', component: Under14InfoComponent },
  { path: 'balance', component: BalanceComponent },
  { path: 'invoicedetails', component: InvoiceDetailsComponent },
  { path: 'billview', component: BillViewComponent },
  { path: 'simplepaymenterror', component: SimplePaymentErrorComponent },
  { path: 'simplepaymentsuccess', component: SimplePaymentSuccessComponent },
  { path: 'simplepaymenttimeout', component : SimplePaymentTimeoutComponent},
  { path: 'simplepaymentcancel', component : SimplePaymentCancelComponent},
  { path: 'bigfishhook', component : BigfishPaymentHandlerComponent},
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider,
    },
    // We need a component here because we cannot define the route otherwise
    component: FakeExternalLinkComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
  declarations: [

  ],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        window.open(externalUrl, '_self');
      }
    },
  ]
})
export class AppRoutingModule { }
