import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SubscriberParameter } from '../../../../../froexishared/src/dto/SubscriberParameter';
import { SubscriberParameterService } from '../../../../../froexishared/src/services/SubscriberParameter.service';
import { Subscription } from 'rxjs';
import SessionStorage from '@app/helpers/SessionStorage';

@Component({
  selector: 'app-simple-verification-dialog',
  templateUrl: './simple-verification-dialog.component.html',
  styleUrls: ['./simple-verification-dialog.component.scss']
})
export class SimpleVerificationDialogComponent implements OnInit,OnDestroy {
  checkedStatus: boolean = false;
  loaded: boolean = false;
  loadSubscriberParameters$ : Subscription;
  merchantParams : MerchantParameters = new MerchantParameters();
  approvalTitle: string;
  approvalBody : string;

  constructor(
    private dialogRef: MatDialogRef<SimpleVerificationDialogComponent>,
    public translate: TranslateService,
    private subscriberParameterService: SubscriberParameterService
  ) { }
  ngOnDestroy(): void {
    this.loadSubscriberParameters$?.unsubscribe()
  }
  
  ngOnInit(): void {
    
    this.loadSubscriberParameters$ = this.subscriberParameterService.loadByTypeCode(SessionStorage.GuestData.Subscriber, SessionStorage.GuestData.HotelId, 
        'MERCHANT_PARAMETERS').subscribe(result => {
        const sub = result as SubscriberParameter
        this.merchantParams = JSON.parse(sub.Param_Json_Value)['MERCHANT']  as MerchantParameters
        this.approvalTitle = this.translate.instant("simple.approval.title")
        this.approvalBody = this.translate.instant("simple.approval.body")
        this.approvalBody = this.approvalBody.replace("#MERCHANT_NAME#",this.merchantParams.MechantName);
        this.approvalBody = this.approvalBody.replace("#MERCHANT_ADDRESS#",this.merchantParams.MerchantAddress);
        this.approvalBody = this.approvalBody.replace("#MERCHANT_WEBSITE#",this.merchantParams.MerchantWebsite)
    })
    

  }

  closeDialog() {
    this.dialogRef.close(false)
  }

  statusCheck(event) {
    event.checked ? this.checkedStatus = true : this.checkedStatus = false
  }

  payClick() {
    this.dialogRef.close({ result: 'acceptedPolicy' })
  }
}

class MerchantParameters {
  MechantName : string;
  MerchantAddress : string;
  MerchantWebsite : string;
}