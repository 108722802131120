// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
// 3rdparty
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
// Hostware
import { BigFish } from '@app/interfaces/BigFish';
import { ActivatedRoute, Router } from '@angular/router';
import { BigfishService } from '@app/services/bigfish.service';
import { BigFishPaymentResponse, BigFishPaymentResultCode } from '@app/dto/BigFishPaymentResponse';
import { TranslateService } from '@ngx-translate/core';
import { GenericMessageDialogComponent } from '@app/dialogs/generic-message-dialog/generic-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GenericMessage } from '@app/interfaces/generic-message';
import { MessageType } from "@app/types/Types";
import { SpinnerService } from '@app/services/spinner.service';
import { error } from 'console';
import { HttpErrorResponse } from '@angular/common/http';
import SessionStorage from '@app/helpers/SessionStorage';

@AutoUnsubscribe()
@Component({
  selector: 'bigfish-payment-handler',
  templateUrl: './bigfish-payment-handler.component.html',
  styleUrls: ['./bigfish-payment-handler.component.scss']
})

export class BigfishPaymentHandlerComponent implements OnInit, OnDestroy {

  getPaymentResult$: Subscription;
  refreshBigFishResult$ : Subscription;
  pendingCallResult$: Subscription;
  
  bigFishPaymentParameters: BigFish;
  transactionId: string;
  hasError: boolean;
  failDescription: string;
  failHeader: string;
  paymentResultCode: string;
  loaded: boolean = false;
  pendingTimerId : any;
  pendingTimerCounter : number = 0;
  messageData: BigFishResultMessage = { messageText: "", messageTitle: "", messageType: 'INFORMATION' }


  
  constructor(

    private router: Router,
    private route: ActivatedRoute,
    private tranlate: TranslateService,
    private bigFishService: BigfishService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService

  ) {

  }
  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit(): void {
  
    
    this.transactionId = this.route.snapshot.queryParams['TransactionId'];
    // let  r :  BigFishPaymentResponse = {
    //   Amount:"10000",
    //   Currency: "HUF",
    //   ProviderTransactionId:"provid",
    //   ResultCode : BigFishPaymentResultCode.PENDING,
    //   ResultMessage: "Hello Bello",
    //   SzepPocket: "szp",
    //   TransactionId : this.transactionId

    // }

    // this.processResponse(r as BigFishPaymentResponse)
    
    this.spinnerService.setSpinnerVisibility(true)
    //ezzel  a transactionId-vel meghívjuk a HW Backendet
    this.bigFishService.GetBigFishResult(this.transactionId).subscribe({
      next: (result) => {
        this.processResponse(result as BigFishPaymentResponse)
        this.spinnerService.setSpinnerVisibility(false)
      },
      error: (err: HttpErrorResponse) => {
        this.spinnerService.setSpinnerVisibility(false)
      }
    })
  }



  //this.showResultDialog(messageData)



  ngOnDestroy(): void {
    this.getPaymentResult$?.unsubscribe();
    this.stopTimer();
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  backClick() {
    this.router.navigateByUrl('/checkinstatus')
  }

  tryAgain() {
    this.startPayment();
  }


  refresh() {
   
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  startPayment() {
    this.bigFishService.GetBigFishResult(this.transactionId).subscribe(result => {
      this.processResponse(result as BigFishPaymentResponse)
    })
  }
  processResponse(response: BigFishPaymentResponse) {
    switch (response.ResultCode) {
      case BigFishPaymentResultCode.CANCELED:
        this.messageData.messageTitle = this.tranlate.instant('bigfish.canceled')
        this.messageData.messageText = this.tranlate.instant('bigfish.canceled_description')
        this.messageData.messageType = 'ERROR'
        break;
      case BigFishPaymentResultCode.OPEN:
        this.messageData.messageTitle = this.tranlate.instant('bigfish.open')
        this.messageData.messageText = this.tranlate.instant('bigfish.open_description')
        this.messageData.messageType = 'ERROR'
        break;
      case BigFishPaymentResultCode.ERROR:
        this.messageData.messageText = this.tranlate.instant('bigfish.error')
        this.messageData.messageText = this.tranlate.instant('bigfish.error_description')
        this.messageData.messageType = 'ERROR'
        break;
      case BigFishPaymentResultCode.TIMEOUT:
        this.messageData.messageText = this.tranlate.instant('bigfish.timeout')
        this.messageData.messageText = this.tranlate.instant('bigfish.timeout_description')
        this.messageData.messageType = 'ERROR'
        break;
      case BigFishPaymentResultCode.PENDING:
        this.messageData.messageText = this.tranlate.instant('bigfish.pending')
        this.messageData.messageText = this.tranlate.instant('bigfish.pending_description')
        this.messageData.messageType = 'ERROR'
        this.startTimer();  
        break;
      case BigFishPaymentResultCode.SUCCESSFUL:
        this.messageData.messageText = this.tranlate.instant('bigfish.successful')
        this.messageData.messageText = this.tranlate.instant('bigfish.successful_description')
        this.messageData.messageType = 'INFORMATION'
        break

    }

    this.loaded = true;

  }

  showResultDialog(messageData: GenericMessage) {
    const okRef = this.dialog.open(GenericMessageDialogComponent, {
      disableClose: true,
      hasBackdrop: true,
      maxWidth: "auto",
      data: messageData
    })
    okRef.afterClosed().subscribe(res => {
      this.router.navigateByUrl('/balance')
    })
  }

  startTimer() : void {
    // Indítja a timer-t, ami 1 másodpercenként növeli a counter értékét
    if (!this.pendingTimerId) {
        this.pendingTimerId = setInterval(() => {
        this.spinnerService.setSpinnerVisibility(true)
        this.bigFishService.RefreshBigFishResult(this.transactionId).subscribe(result=> {
          const res = result as BigFishPaymentResponse
          this.processResponse(res)
          this.paymentResultCode = res.ResultCode;
          if(res.ResultCode !== BigFishPaymentResultCode.PENDING) {
            this.stopTimer()
            this.spinnerService.setSpinnerVisibility(false)
          }
        })

        this.pendingTimerCounter++
        if( this.pendingTimerCounter > 6) {
          this.stopTimer();
        }
      }, 5000);  // 5000 ms = 5 másodperc
    }
  }

  stopTimer() {
    // Leállítja a timer-t
    if (this.pendingTimerId) {
      clearInterval(this.pendingTimerId);
      this.pendingTimerId = null; // Tisztítjuk az interval ID-t, hogy újraindítható legyen
      this.spinnerService.setSpinnerVisibility(false)
    }
  }

  retryVisible() : boolean {
    if( this.paymentResultCode == BigFishPaymentResultCode.TIMEOUT || this.paymentResultCode == BigFishPaymentResultCode.ERROR) {
      return true
    }  else {
      return false
    }
  }

  okEnabled() :boolean {
    return false
  }

  
  //#endregion =================================================================================================================




}



export interface BigFishResultMessage {
  messageTitle: string;
  messageText: string;
  messageType: MessageType;


}
