import { Injectable } from "@angular/core";
import { UrlData } from "@app/interfaces/UrlData";
import { Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class UrlDataService {
    private _urlData = new Subject();
    urlData$ = this._urlData.asObservable();

    constructor() {
    }
    setUrlData(data: UrlData) {
        if (data.guestStayUuid && data.hotel3pId) {
            this._urlData.next(data)
        }
    }
}