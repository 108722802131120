import dayjs from "dayjs";
import SessionStorage from "./SessionStorage";

export default class ChatVisibilityHelper {

  public static get  ChatVisibleStyle() {
    let styles = {};
    
    if( SessionStorage.ChatVisibility == "N") {
      styles["display"] = "none"
    }
    if( SessionStorage.ChatVisibility == "I") {
      styles["display"] = "block"
    }
    
    
    // idő intervallum van megadva
    if (SessionStorage.ChatVisibility.includes(",")) {
      const now = dayjs();
      const splitted = SessionStorage.ChatVisibility.split(',')
      if(now.hour() >= parseInt(splitted[0]) && now.hour() <= parseInt(splitted[1]) )  {
        styles["display"] = "block"
      } else {
        styles["display"] = "none"
      }
    }
    
    
    return styles;   

     
  }

  
}