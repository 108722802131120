import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bye-bye',
  templateUrl: './bye-bye.component.html',
  styleUrls: ['./bye-bye.component.scss']
})
export class ByeByeComponent implements OnInit {

  constructor(
    
  ) { }

  ngOnInit() {
  }

}
